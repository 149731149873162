import shopApi from '_api/shop'
import { setListShopPaid } from '_utils/localData'

const GET_LIST_SHOP_PAID = 'GET_LIST_SHOP_PAID'
const CLEAR_LIST_SHOP_PAID = 'CLEAR_LIST_SHOP_PAID'
const GET_MY_SHOPS = 'GET_MY_SHOPS'
const APPEND_NEW_EVENT_TO_SHOP = 'APPEND_NEW_EVENT_TO_SHOP'
const UPDATE_EVENT_IN_SHOP = 'UPDATE_EVENT_IN_SHOP'
const UPDATE_TOTAL_ORDER_COUNT_IN_SHOP_WHEN_CANCEL_ORDER =
  'UPDATE_TOTAL_ORDER_COUNT_IN_SHOP_WHEN_CANCEL_ORDER'

const initialState = {
  listShop: [],
  myShops: [],
  isLoadingMyShops: true,
  cachedMyShops: null,
}

export const updateTotalOrderCountInShopWhenOrderWhenCancelOrder =
  (orderDetail) => async (dispatch, getState) => {
    const { shop } = getState()
    const { myShops } = shop
    const { event: eventDetail, productOrder } = orderDetail

    if (!myShops || !myShops.length) {
      return
    }

    const indexInMyGroup = myShops?.findIndex((item) => item.id === eventDetail.groupId)
    const indexEventInMyGroup = myShops[indexInMyGroup]?.events?.findIndex(
      (item) => item.id === eventDetail.id
    )

    const count = productOrder.allProductPerOrder.reduce((a, b) => a + b.pQuantity, 0)

    if (indexEventInMyGroup !== -1) {
      myShops[indexInMyGroup].events[indexEventInMyGroup].totalOrderCount -= count

      dispatch({
        type: UPDATE_TOTAL_ORDER_COUNT_IN_SHOP_WHEN_CANCEL_ORDER,
        payload: { myShops: [...myShops] },
      })
    }
  }

export const appendNewEventToShop = (data) => async (dispatch, getState) => {
  const { shop } = getState()
  const { myShops } = shop
  const { shopId } = data

  const index = myShops.findIndex((item) => item.id === shopId)
  if (index !== -1) {
    const newEvent = { ...data }

    const newMyShops = myShops.map((shopItem, shopIndex) => {
      if (shopIndex !== index) return shopItem

      return {
        ...shopItem,
        totalEvents: shopItem.totalEvents + 1,
        events: Array.isArray(shopItem.events) ? [newEvent, ...shopItem.events] : [newEvent],
      }
    })

    // const newListEventByShop = {
    //   ...eventsByShop,
    //   [shopId]: Array.isArray(eventsByShop[shopId])
    //     ? [newEvent, ...eventsByShop[shopId]]
    //     : [newEvent],
    // }

    dispatch({
      type: APPEND_NEW_EVENT_TO_SHOP,
      payload: { myShops: newMyShops },
    })
  }
}

export const updateEventInShop = (data) => async (dispatch, getState) => {
  const { shop } = getState()
  const { myShops } = shop
  const { shopId, id } = data
  const index = myShops?.findIndex((item) => item.id === shopId) || -1
  if (index !== -1) {
    const eventIndex = myShops[index]?.events?.findIndex((k) => k.id === id) || -1
    if (eventIndex !== -1) {
      myShops[index].events[eventIndex] = data
      dispatch({ type: UPDATE_EVENT_IN_SHOP, payload: { myShops } })
    }
  }
}

export const getMyShops = () => async (dispatch, getState) => {
  try {
    const { shop } = getState()
    const { cachedMyShops } = shop
    if (cachedMyShops) {
      dispatch({
        type: GET_MY_SHOPS,
        payload: {
          myShops: cachedMyShops,
        },
      })
      return
    }

    const { msgResp } = await shopApi.getListShopByHost()

    dispatch({
      type: GET_MY_SHOPS,
      payload: {
        myShops: msgResp,
        cachedMyShops: msgResp,
      },
    })
  } catch (error) {
    dispatch({
      type: GET_MY_SHOPS,
      payload: {
        myShops: [],
      },
    })
  } finally {
    dispatch({
      type: GET_MY_SHOPS,
      payload: {
        isLoadingMyShops: false,
      },
    })
  }
}

export const getListShopPaid = () => async (dispatch) => {
  try {
    const { msgResp } = await shopApi.getListShopPaid()

    dispatch({
      type: GET_LIST_SHOP_PAID,
      payload: {
        listShop: msgResp,
      },
    })

    setListShopPaid(msgResp)
  } catch (error) {
    throw new Error(error)
  }
}

export const clearShopPaid = () => (dispatch) => {
  dispatch({
    type: CLEAR_LIST_SHOP_PAID,
    payload: {
      listShop: [],
    },
  })
  setListShopPaid([])
}

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TOTAL_ORDER_COUNT_IN_SHOP_WHEN_CANCEL_ORDER:
    case UPDATE_EVENT_IN_SHOP:
    case APPEND_NEW_EVENT_TO_SHOP:
    case GET_MY_SHOPS:
    case CLEAR_LIST_SHOP_PAID:
    case GET_LIST_SHOP_PAID:
      return {
        ...state,
        ...action.payload,
      }
    default: {
      return state
    }
  }
}

export default shopReducer
