import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import ConfirmPage from './Confirm'
import EventCreate from './Create'
import EventCreateItem from './CreateItem'
import DeliveryDetail from './DeliveryDetail'
import EventDetail from './EventDetail'
import ListAllEventPlus from './ListAllEventPlus'
import ListMyPurchases from './ListMyPurchases'
import ManageDelivery from './ManageDelivery'
import ManageOrder from './ManageOrder'
import MyEventList from './MyEventList'
import PurchaseDetail from './PurchaseDetail'
import './style.scss'

const Event = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/created-confirm`} component={ConfirmPage} />
      <Route path={`${path}/create-items`} component={EventCreateItem} />
      <Route path={`${path}/create`} component={EventCreate} />
      <Route path={`${path}/update-confirm`} component={ConfirmPage} />
      <Route path={`${path}/update-items`} component={EventCreateItem} />
      <Route path={`${path}/update`} component={EventCreate} />
      <Route path={`${path}/list-my-events`} component={MyEventList} />
      <Route path={`${path}/list-my-purchases`} component={ListMyPurchases} />
      <Route path={`${path}/detail`} component={EventDetail} />
      <Route path={`${path}/manage-orders`} component={ManageOrder} />
      <Route path={`${path}/purchase-detail`} component={PurchaseDetail} />
      <Route path={`${path}/detail-shared`} component={EventDetail} />
      <Route path={`${path}/delivery-detail`} component={DeliveryDetail} />
      <Route path={`${path}/manage-delivery`} component={ManageDelivery} />
      <Route path={`${path}/list-all-event-plus`} component={ListAllEventPlus} />
    </Switch>
  )
}

export default Event
