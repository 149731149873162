/* eslint-disable array-callback-return */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import deliveryApi from '_api/delivery'
import { getListDeliveryCreatedByHost, getScheduledDelivery } from '_redux/modules/delivery'
import { getMyEvents, getScheduledEvent } from '_redux/modules/event'
import { DEFAULT_DELIVERY_OPTION, GROUP_IS_HOST, LIST_TIME } from '_utils/constant'
import {
  convertTimeStringWithDuration,
  convertToCurrentGMT,
  convertToGMT0,
  sortListEventsByPickupTimestamp,
} from '_utils/function'
import { getUserInfo } from '_utils/localData'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

import { getMyShops } from '_redux/modules/shop'
import { addToListDeliveryCreatedByHost } from '../../../redux/modules/delivery'
import { getMyShopEvents } from '../../../redux/modules/event'
import { getMyGroups } from '../../../redux/modules/group'
import { idGenerator } from '../../../utils/function'
import BoxDeliveryDateByHost from '../components/BoxDeliveryDateByHost'
import DeliveryOptionsModal from '../components/DeliveryOptionsModal'
import MyEventItem from '../components/MyEventItem'
import MyScheduledDelivery from '../components/MyScheduleDelivery'
import MyScheduledEvent from '../components/MyScheduledEvent'
import './style.scss'

const now = moment().unix()

const MyEventList = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const userInfo = getUserInfo()
  if (!userInfo) {
    addToast('User info not found', { appearance: 'error', autoDismiss: true })
    return null
  }
  const { id: userId } = userInfo
  // const userHasShop = userInfo?.shopLinks && userInfo.shopLinks.length > 0
  const { myGroups } = useSelector((state) => state.group)
  const { myShops } = useSelector((state) => state.shop)
  const { myEvents, myShopEvents, allScheduleEvent } = useSelector((state) => state.event)
  const { listDeliveryCreatedByHost, allScheduleDelivery, loadingDelivery } = useSelector(
    (state) => state.delivery
  )

  let { listDeliveryCreated = null } =
    listDeliveryCreatedByHost.find((item) => item.hostId === userId) || {}

  listDeliveryCreated = listDeliveryCreated
    ? listDeliveryCreated.sort((a, b) => a.deliveryTimestamp - b.deliveryTimestamp)
    : null

  const { listScheduleDelivery = null } =
    allScheduleDelivery.find((item) => item.hostId === userId) || {}

  const [isLoading, setIsLoading] = useState(true)
  const [textBtn, setTextBtn] = useState('COPY')
  const [shareLink, setShareLink] = useState('')
  const [modal, setModal] = useState(false)
  const [openModalAddDelivery, setOpenModalAddDelivery] = useState(false)
  const [errorMessageDeliveryZone, setErrorMessageDeliveryZone] = useState('')
  const [combinedListMyEvents, setCombinedListMyEvents] = useState([])
  const [optionsEntity, setOptionsEntity] = useState([])
  const [selectedEntity, setSelectedEntity] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [deliveryZones, setDeliveryZones] = useState([])
  const [deliveryHourStart, setDeliveryHourStart] = useState('10AM')
  const [deliveryHourEnd, setDeliveryHourEnd] = useState('1PM')
  const [cachedGroupDeliveryZones, setCachedGroupDeliveryZones] = useState(null)
  const [deliveryTime, setDeliveryTime] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  )
  const [zoneErrors, setZoneErrors] = useState({})

  const toggle = () => setModal(!modal)

  const toggleModalAddDelivery = () => {
    setOpenModalAddDelivery(!openModalAddDelivery)
  }

  const handleOnCopy = () => {
    navigator.clipboard.writeText(shareLink)
    setTextBtn('COPIED')
  }

  const isCloseEvent = (pickupTimestamp) => {
    if (pickupTimestamp < now) {
      return true
    }
    return false
  }

  const validatePrice = (price) => price >= 0
  const validateZoneName = (name) => name.trim() !== ''

  const validateDeliveryZone = (zone) => {
    const errors = {}

    if (!validateZoneName(zone.name)) {
      errors.name = 'Name is required'
    }

    if (!validatePrice(zone.price)) {
      errors.price = 'Price must be greater than 0'
    }

    return errors
  }

  const onChangeZoneDelivery = (index, type, value) => {
    const updatedZones = [...deliveryZones]
    updatedZones[index][type] = value
    setDeliveryZones(updatedZones)

    // Update errors only for the changed zone
    const errors = { ...zoneErrors }
    errors[index] = validateDeliveryZone(updatedZones[index])
    setZoneErrors(errors)
  }

  const hasValidationErrors = () =>
    // Check validation for all zones without updating state
    deliveryZones.some((zone) => {
      const errors = validateDeliveryZone(zone)
      return Object.keys(errors).length > 0
    })

  const handleOnClickOk = async () => {
    const deliveryStartIndex = LIST_TIME.findIndex((item) => item.text === deliveryHourStart)
    const deliveryEndIndex = LIST_TIME.findIndex((item) => item.text === deliveryHourEnd)
    const timeStart = LIST_TIME[deliveryStartIndex].value
    const timeEnd = LIST_TIME[deliveryEndIndex].value
    const deliveryDuration = (timeEnd - timeStart) * 3600
    if (deliveryDuration < 0) {
      addToast('Invalid delivery time duration', { appearance: 'error', autoDismiss: true })
      setIsSubmitting(false)
      return
    }

    const rememberedDeliveryZones = deliveryZones
      .map(({ name, price }) => ({ name, price }))
      .filter((item) => item.name && item.price >= 0)

    if (!rememberedDeliveryZones.length) {
      setErrorMessageDeliveryZone('Please fill in all delivery zones')
      setIsSubmitting(false)
      return
    }

    if (hasValidationErrors()) {
      setIsSubmitting(false)
      return
    }

    const deliveryTimeLocal = `${moment(deliveryTime).format('DD-MM-YYYY')} ${timeStart}:00`
    const deliveryTimestamp = moment(deliveryTimeLocal, 'DD-MM-YYYY HH:mm').unix()

    if (deliveryTimestamp < now) {
      setIsSubmitting(false)
      addToast('Delivery time must after current time', { appearance: 'error', autoDismiss: true })
      return
    }

    const deliveryTimeGMT0 = convertToGMT0(deliveryTimeLocal)

    const findGroupIndex = myGroups?.findIndex((item) => item.id === selectedEntity)
    const findShopIndex = myShops?.findIndex((item) => item.id === selectedEntity)

    try {
      setIsSubmitting(true)
      const payload = {
        deliveryTime: deliveryTimeGMT0,
        deliveryDuration,
      }

      if (findGroupIndex !== -1) {
        payload.groupId = selectedEntity
        payload.isEventShop = false
      } else if (findShopIndex !== -1) {
        payload.shopId = selectedEntity
        payload.isEventShop = true
      }

      const { msgResp } = await deliveryApi.createDeliveryByHost(payload, payload.isEventShop)

      dispatch(addToListDeliveryCreatedByHost(userId, msgResp))
      setIsSubmitting(false)
      toggleModalAddDelivery()
      addToast('Added delivery', { appearance: 'success', autoDismiss: true })

      const updateData = { rememberedDeliveryZones }

      await deliveryApi.updateRememberedDeliveryZones(
        userId,
        updateData,
        payload.isEventShop,
        payload.isEventShop ? selectedEntity : null
      )
    } catch (error) {
      addToast(error?.msgResp, { appearance: 'error', autoDismiss: true })
    } finally {
      setIsSubmitting(false)
      toggleModalAddDelivery()
    }
  }

  const onChangeTimeDelivery = (e) => {
    setDeliveryTime(e)
  }

  const handleAddMoreDeliveryZone = () => {
    const findGroupIndex = myGroups?.findIndex((item) => item.id === selectedEntity)

    const newDeliveryZone = {
      name: '',
      price: 0,
      type: findGroupIndex !== -1 ? 'group' : 'shop',
    }

    setDeliveryZones((prevZones) => [...prevZones, newDeliveryZone])

    // Add validation for new zone
    const newIndex = deliveryZones.length
    const validation = validateDeliveryZone(newDeliveryZone)
    setZoneErrors((prev) => ({
      ...prev,
      [newIndex]: validation,
    }))
  }

  const handleOnRemoveDeliveryZone = (index) => {
    const updatedZones = deliveryZones.filter((_, i) => i !== index)
    setDeliveryZones(updatedZones)

    // Remove errors for deleted zone
    const updatedErrors = { ...zoneErrors }
    delete updatedErrors[index]
    setZoneErrors(updatedErrors)
  }

  const onOpenAddDelivery = async () => {
    const deliveryZonesGroup = await deliveryApi.getRememberedDeliveryZones('', false)

    const newDeliveryZones = deliveryZonesGroup.msgResp.length
      ? deliveryZonesGroup.msgResp
      : [...deliveryZones, DEFAULT_DELIVERY_OPTION]

    setDeliveryZones(newDeliveryZones)
    toggleModalAddDelivery()
  }

  useEffect(() => {
    let isMounted = true

    const getEvents = async () => {
      await Promise.all([
        dispatch(getMyEvents),
        dispatch(getScheduledEvent),
        dispatch(getMyGroups),
        dispatch(getMyShops()),
        dispatch(getMyShopEvents),
        dispatch(() => getScheduledDelivery(userId), 'scheduledDelivery'),
      ])

      dispatch(getListDeliveryCreatedByHost(userId, null))

      if (isMounted) {
        setIsLoading(false)
      }
    }

    getEvents()

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    const loadData = async () => {
      const shopIds = myShops.map((shop) => shop.id).filter((id) => id)

      if (shopIds.length) {
        await Promise.all(
          shopIds.map((shopId) => dispatch(getListDeliveryCreatedByHost(userId, shopId)))
        )
      }
    }

    loadData()
  }, [myShops.length])

  useEffect(() => {
    const combinedEvents = [...myEvents, ...myShopEvents]
    setCombinedListMyEvents(combinedEvents)
  }, [myShopEvents.length, myEvents.length])

  useEffect(() => {
    if (myGroups && (myGroups.length || myShops.length)) {
      const hostForGroup = myGroups?.filter((group) => group.isHost === GROUP_IS_HOST)
      const combinedGroupShop = [...hostForGroup, ...myShops]
        .sort((a, b) => {
          if (a.id && !b.id) return -1 // field "id" is for group.id, else is for shop.shopId
          if (!a.id && b.id) return 1
          return 0
        })
        .map((item) => ({
          ...item,
          name: item.name || item.shopName,
        }))

      // Only set selectedEntity if it hasn't been set yet
      if (!selectedEntity && combinedGroupShop.length > 0) {
        setSelectedEntity(combinedGroupShop[0]?.gid || combinedGroupShop[0]?.id)
      }

      if (listDeliveryCreated && Array.isArray(listDeliveryCreated)) {
        const deliveryZonesFound = listDeliveryCreated.find(
          (item) =>
            item.groupId === combinedGroupShop[0]?.gid || item.shopId === combinedGroupShop[0]?.id
        )?.deliveryZones

        if (deliveryZonesFound) {
          setDeliveryZones(deliveryZonesFound)
        }
      }

      setOptionsEntity(combinedGroupShop)
      // setSelectedEntity(combinedGroupShop[0]?.gid || combinedGroupShop[0]?.id)
    }
  }, [myGroups, JSON.stringify(myShops), listDeliveryCreated])

  useEffect(() => {
    if (!openModalAddDelivery) {
      setErrorMessageDeliveryZone('')
    }
  }, [openModalAddDelivery])

  // useEffect(() => {
  //   dispatch(getMulListDeliveryCreatedByHost([userId], []))
  // }, [])

  useEffect(() => {
    const fetchDeliveryZones = async () => {
      if (
        listDeliveryCreated &&
        Array.isArray(listDeliveryCreated) &&
        selectedEntity &&
        selectedEntity !== ''
      ) {
        const isGroup = myGroups?.find((g) => g.id === selectedEntity)
        const isShop = myShops.find((s) => s.id === selectedEntity)

        try {
          if (isGroup) {
            // For groups, use cached data if available
            if (!cachedGroupDeliveryZones) {
              const { msgResp } = await deliveryApi.getRememberedDeliveryZones('', false)
              setDeliveryZones(msgResp)
              setCachedGroupDeliveryZones(msgResp) // Cache the response
            } else {
              setDeliveryZones(cachedGroupDeliveryZones)
            }
          } else if (isShop) {
            // For shops, always fetch fresh data
            const { msgResp } = await deliveryApi.getRememberedDeliveryZones(selectedEntity, true)

            if (msgResp.length === 0) {
              setDeliveryZones([{ name: '', price: 0, id: idGenerator() }])
            } else {
              setDeliveryZones(msgResp)
            }
            // Reset group cache when switching to shop
            setCachedGroupDeliveryZones(null)
          }
        } catch (error) {
          console.error('Error fetching delivery zones:', error)
        }
      }
    }

    fetchDeliveryZones()
  }, [selectedEntity, listDeliveryCreated])

  return (
    <div className='row-top my-events-page'>
      {isLoading && <div>Loading...</div>}
      {!isLoading ? (
        <>
          <div
            className='text-add-delivery d-flex flex-row-reverse mt-2 mb-2'
            onClick={onOpenAddDelivery}
          >
            Add delivery
          </div>

          {loadingDelivery && <div>Loading...</div>}

          {listDeliveryCreated && listDeliveryCreated.length
            ? listDeliveryCreated
                .filter((ele) => ele.deliveryTimestamp >= now)
                .sort((a, b) => {
                  // Đưa các phần tử của group lên trên
                  if (a.groupId && !b.groupId) return -1
                  if (!a.groupId && b.groupId) return 1
                  // Nếu cùng loại (cả hai là group hoặc cả hai là shop), sắp xếp theo thời gian
                  return a.deliveryTimestamp - b.deliveryTimestamp
                })
                .map((item, idex) => (
                  <BoxDeliveryDateByHost
                    key={idex}
                    createdUserPhotoUrl={item.createdUserPhotoUrl}
                    createdUserName={item.createdUserName}
                    entityName={item.groupName || item.shopName}
                    deliveryTimeString={convertTimeStringWithDuration(
                      item.deliveryTime,
                      item.deliveryDuration
                    )}
                    deliveryTime={convertToCurrentGMT(item.deliveryTime)}
                    deliveryId={item.id}
                    deliveryShopId={item?.shopId}
                  />
                ))
            : null}

          {sortListEventsByPickupTimestamp(combinedListMyEvents)
            ?.filter((item) => !isCloseEvent(item.pickupTimestamp))
            .map((event, index) => (
              <MyEventItem
                event={event}
                openModal={toggle}
                setShareLink={setShareLink}
                key={index}
              />
            ))}

          {listScheduleDelivery?.map((delivery, index) => (
            <MyScheduledDelivery key={index} delivery={delivery} hostId={userId} />
          ))}

          {allScheduleEvent?.map((event, index) => (
            <MyScheduledEvent
              event={event}
              openModal={toggle}
              setShareLink={setShareLink}
              isScheduledEvent={true}
              key={index}
            />
          ))}

          {listDeliveryCreated && listDeliveryCreated.length
            ? listDeliveryCreated
                .filter((ele) => ele.deliveryTimestamp < now)
                .map((item, idex) => (
                  <BoxDeliveryDateByHost
                    key={idex}
                    createdUserPhotoUrl={item.createdUserPhotoUrl}
                    createdUserName={item.createdUserName}
                    entityName={item?.groupName || item?.shopName}
                    deliveryTimeString={convertTimeStringWithDuration(
                      item.deliveryTime,
                      item.deliveryDuration
                    )}
                    deliveryTime={convertToCurrentGMT(item.deliveryTime)}
                    deliveryId={item.id}
                    greyOut={true}
                  />
                ))
            : null}

          {sortListEventsByPickupTimestamp(combinedListMyEvents)
            ?.filter((item) => isCloseEvent(item.pickupTimestamp))
            .map((event, index) => (
              <MyEventItem
                event={event}
                openModal={toggle}
                setShareLink={setShareLink}
                key={index}
              />
            ))}

          {combinedListMyEvents.length === 0 && !isLoading && (
            <div>You don&apos;t have any events!!!</div>
          )}

          <Modal isOpen={modal} toggle={toggle} centered>
            <ModalHeader className='modal-header-created'>
              <span className='c-txt-gray'>Share Link</span>
            </ModalHeader>
            <ModalBody>
              <div className='c-form'>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control form-control--radius shareLink'
                    value={shareLink}
                    readOnly
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter className='modal-footer-created'>
              <div className='col-12'>
                <button className='btn btn--org btn--org02' onClick={handleOnCopy}>
                  {textBtn}
                </button>
              </div>
              <div className='col-12 mt-2'>
                <button className='btn btn--solid btn--solid04' onClick={toggle}>
                  CLOSE
                </button>
              </div>
            </ModalFooter>
          </Modal>

          {openModalAddDelivery && (
            <DeliveryOptionsModal
              modal={openModalAddDelivery}
              toggle={toggleModalAddDelivery}
              handleOnClickOk={handleOnClickOk}
              deliveryZones={deliveryZones}
              deliveryTime={deliveryTime}
              onChangeZoneDelivery={onChangeZoneDelivery}
              onChangeTimeDelivery={onChangeTimeDelivery}
              handleAddMoreDeliveryZone={handleAddMoreDeliveryZone}
              handleOnRemoveDeliveryZone={handleOnRemoveDeliveryZone}
              deliveryHourStart={deliveryHourStart}
              setDeliveryHourStart={setDeliveryHourStart}
              deliveryHourEnd={deliveryHourEnd}
              setDeliveryHourEnd={setDeliveryHourEnd}
              selectedEntity={selectedEntity}
              setSelectedEntity={setSelectedEntity}
              optionsEntity={optionsEntity}
              isSubmitting={isSubmitting}
              errorMessageDeliveryZone={errorMessageDeliveryZone}
              zoneErrors={zoneErrors}
              hasValidationErrors={hasValidationErrors}
            />
          )}
        </>
      ) : null}
    </div>
  )
}

export default MyEventList
