import { GROUP_IS_ADMIN, GROUP_IS_HOST } from '_utils/constant'
import { getUserInfo, setGroupIdSelected } from '_utils/localData'
import moment from 'moment'
import { createContext, useEffect, useState } from 'react'
import useQuery from '../hooks/useQuery'
import { getGroupIdSelected } from '../utils/localData'

export const SelectIdGroupContext = createContext()

function SelectGroupContext({ children }) {
  const params = useQuery()
  const userInfo = getUserInfo()
  // const { groupById } = useSelector((state) => state.group)

  const shopIdParam = params.get('shopId')

  const [infoGroupSelected, setInfoGroupSelected] = useState({})
  const [eventQuantity, setEventQuantity] = useState()
  const [dataWhenSync, setDataWhenSync] = useState({
    startDate: moment().subtract(1, 'month').format('DD-MM-YYYY'),
    endDate: moment().format('DD-MM-YYYY'),
  })

  const setSelectedGroup = (group) => {
    setGroupIdSelected(group.groupId)
    setInfoGroupSelected({
      groupId: group.groupId,
      groupName: group.name,
      totalMembers: group.totalMembers,
    })
  }

  const getFirstGroup = () => {
    const myFirstGroup = userInfo?.groups?.filter(
      (item) => item.isHost === GROUP_IS_HOST || item.isAdmin === GROUP_IS_ADMIN
    )[0]
    return myFirstGroup
  }

  useEffect(() => {
    if (userInfo?.groups?.length) {
      if (getGroupIdSelected()) {
        const myGroups = userInfo?.groups
        const groupSelected = myGroups?.find((group) => group.gid === getGroupIdSelected())
        setInfoGroupSelected({
          groupId: groupSelected?.gid,
          groupName: groupSelected?.name,
          totalMembers: groupSelected?.totalMembers,
        })
      } else if (!shopIdParam) {
        const myFirstGroup = getFirstGroup()

        setGroupIdSelected(myFirstGroup?.gid)
        setInfoGroupSelected({
          groupId: myFirstGroup?.gid,
          groupName: myFirstGroup?.name,
          totalMembers: myFirstGroup?.totalMembers,
        })
      }
    }
  }, []) // JSON.stringify(userInfo), getGroupIdSelected()

  return (
    <SelectIdGroupContext.Provider
      value={{
        setInfoGroupSelected,
        infoGroupSelected,
        eventQuantity,
        setEventQuantity,
        dataWhenSync,
        setDataWhenSync,
        setSelectedGroup, // Add this line
      }}
    >
      {children}
    </SelectIdGroupContext.Provider>
  )
}

export default SelectGroupContext
