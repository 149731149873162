import classNames from 'classnames'
import { truncate } from 'lodash'
import { Fragment, useEffect, useRef, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { Tooltip } from 'reactstrap'

const OptionWithTooltip = ({ option, handleSelect }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen)

  return (
    <>
      <div id={`tooltip-${option.id}`} className='option' onClick={() => handleSelect(option)}>
        {truncate(option.name, {
          length: 10,
        })}
      </div>
      {option.name.length > 10 && mounted && (
        <Tooltip
          isOpen={tooltipOpen}
          target={`tooltip-${option.id}`}
          toggle={toggleTooltip}
          placement={'right'}
          autohide={false}
          className={'tooltip-event-item'}
        >
          <span className={'f-12'}>{option.name}</span>
        </Tooltip>
      )}
    </>
  )
}

const CustomSelectBox = ({ options, selectedValue, onChange, isDisabled }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState({})
  const ref = useRef()

  const disabledStyle = {
    pointerEvents: 'none',
    backgroundColor: 'rgb(222, 226, 230)',
    filter: 'grayscale(100%)',
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current?.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  useEffect(() => {
    setSelected(selectedValue)
  }, [selectedValue])

  const handleSelect = (value) => {
    setSelected(value)
    onChange(value)
    setIsOpen(false)
  }

  return (
    <div className='select-box' ref={ref}>
      <div
        className='selected'
        onClick={() => setIsOpen(!isOpen)}
        style={isDisabled ? disabledStyle : {}}
      >
        {truncate(selected.groupName || 'Select group', {
          length: 14,
        })}

        {isOpen ? <FaChevronUp className='arrow-icon' /> : <FaChevronDown className='arrow-icon' />}
      </div>
      {isOpen && (
        <div className={classNames('options', { open: isOpen })}>
          {options.map((option) => (
            <Fragment key={option.id}>
              <OptionWithTooltip option={option} handleSelect={handleSelect} />
            </Fragment>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomSelectBox
