/* eslint-disable no-plusplus */
/* eslint-disable function-paren-newline */
import { SelectIdGroupContext } from '_context/SelectGroupContext'
import {
  getListDeliveryAndDeliveryBooking,
  getListDeliveryCreatedByHost,
} from '_redux/modules/delivery'
import {
  DELIVERY_BUYER_CANCELLED,
  DELIVERY_BUYER_PAID,
  DELIVERY_BUYER_REFUND,
  DELIVERY_BUYER_UNPAID,
  DELIVERY_HOST_CANCELLED,
  DELIVERY_HOST_PAID,
  DELIVERY_HOST_UNPAID,
} from '_utils/constant'
import { convertTimeStringWithDuration, normalizeName } from '_utils/function'
import { getUserInfo } from '_utils/localData'
import moment from 'moment'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Button, Col, Row } from 'reactstrap'
import * as XLSX from 'xlsx'
import DeliveryList from './components/DeliveryList'
import Pagination from './components/Pagination'
import SearchDelivery from './components/SearchDelivery'
import SelectFilter from './components/SelectFilter'
import './style.scss'

function DeliveryBooking() {
  const dispatch = useDispatch()
  const userInfo = getUserInfo()
  const { addToast } = useToasts()
  const { infoGroupSelected } = useContext(SelectIdGroupContext)
  const groupId = infoGroupSelected?.groupId

  const {
    errorDeliveryAndDeliveryBooking,
    listDeliveryCreatedByHost,
    currentDeliveryAndDeliveryBooking,
  } = useSelector((state) => state.delivery)
  const deliveryBookings = currentDeliveryAndDeliveryBooking?.deliveryBookings || []

  const listDeliveryBooking = useMemo(
    () =>
      deliveryBookings
        .filter((item) => item.dGroupId === groupId)
        .sort((a, b) => b.createdAt - a.createdAt),
    [deliveryBookings, groupId]
  )

  const [searchString, setSearchString] = useState('')
  const [filter, setFilter] = useState({
    rangeDate: [{ startDate: null, endDate: null, key: 'selection' }],
    paymentStatus: [],
  })
  const [page, setPage] = useState({ currentPage: 1, deliveriesPerPage: 10 })
  const [sorting, setSorting] = useState({ field: 'eOrderNo', order: 'asc', type: 'number' })

  const { currentPage, deliveriesPerPage } = page
  const { rangeDate, paymentStatus } = filter
  const paymentStatusValue = paymentStatus.map((item) => item.value)

  // Logic for displaying Deliveries
  const indexOfLastDelivery = currentPage * deliveriesPerPage
  const indexOfFirstDelivery = indexOfLastDelivery - deliveriesPerPage

  // Logic for displaying page numbers
  const pageNumbers = Array.from(
    { length: Math.ceil(deliveryBookings.length / deliveriesPerPage) },
    (_, i) => i + 1
  )

  useEffect(() => {
    if (userInfo?.id && groupId) {
      dispatch(getListDeliveryCreatedByHost(userInfo.id, ''))
    }
  }, [userInfo?.id, groupId])

  useEffect(() => {
    const fetchDeliveryOrder = async () => {
      if (listDeliveryCreatedByHost?.length && groupId) {
        try {
          const listDeliveryCreated =
            listDeliveryCreatedByHost[0]?.listDeliveryCreated?.filter(
              (item) => item.groupId === groupId
            ) || []

          const listDeliveryId = listDeliveryCreated.map((item) => item.id)

          if (!listDeliveryId.length) {
            dispatch({ type: 'LOADING_DELIVERY_END' })
            return
          }

          const isDesktop = true
          await Promise.all(
            listDeliveryId.map((deliveryId) =>
              dispatch(getListDeliveryAndDeliveryBooking(deliveryId, false, isDesktop))
            )
          )
        } catch (error) {
          addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
        }
      }
    }

    fetchDeliveryOrder()
  }, [listDeliveryCreatedByHost, groupId])

  const onChangeSearch = (e) => setSearchString(e.target.value)

  const handleClearFilter = () => {
    setFilter({
      ...filter,
      rangeDate: [{ startDate: null, endDate: null, key: 'selection' }],
      paymentStatus: [],
    })
    setSearchString('')
  }

  const handleChangeRangeDate = (data) => {
    const formatStartDate = new Date(data[0].startDate.setHours(0, 0, 0, 0))
    const formatEndDate = new Date(data[0].endDate.setHours(23, 59, 59, 999))

    setFilter({
      ...filter,
      rangeDate: [{ startDate: formatStartDate, endDate: formatEndDate, key: 'selection' }],
    })
  }

  const handleChangePaymentStatus = (options) => {
    const optionSelected = options.selectedOption.map((option) => option)
    setFilter({
      ...filter,
      paymentStatus: [...optionSelected],
    })
  }

  const handlePrev = () => {
    if (currentPage > 1) {
      setPage((prev) => ({ ...prev, currentPage: currentPage - 1 }))
    }
  }

  const handleNext = () => {
    if (currentPage < pageNumbers.length) {
      setPage((prev) => ({ ...prev, currentPage: currentPage + 1 }))
    }
  }

  const listDeliveriesFiltered = useMemo(() => {
    const { startDate, endDate } = rangeDate[0]
    let filteredDeliveries = listDeliveryBooking?.length ? [...listDeliveryBooking] : []

    if (startDate && endDate) {
      filteredDeliveries = filteredDeliveries.filter((item) =>
        moment(item.createdAt * 1000).isBetween(startDate, endDate)
      )
    }

    if (paymentStatus.length > 0) {
      filteredDeliveries = filteredDeliveries.filter((item) =>
        paymentStatusValue.includes(item.bookerStatus)
      )
    }

    if (searchString) {
      filteredDeliveries = filteredDeliveries.filter((item) => {
        const valueAsString = JSON.stringify(Object.values(item))
        return valueAsString.toLowerCase().includes(searchString.toLowerCase())
      })
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1
      filteredDeliveries.sort((a, b) => {
        if (sorting.type === 'string') {
          return reversed * a[sorting.field]?.localeCompare(b[sorting.field])
        }
        return reversed * (a[sorting.field] - b[sorting.field])
      })
    }

    return filteredDeliveries.slice(indexOfFirstDelivery, indexOfLastDelivery)
  }, [
    rangeDate,
    JSON.stringify(listDeliveryBooking),
    paymentStatus,
    searchString,
    sorting,
    indexOfFirstDelivery,
    indexOfLastDelivery,
  ])

  const showNote = (rangeDates) => (
    <div className='d-flex align-items-center justify-content-between'>
      <div className='list-event-note'>
        Click on <strong>Names</strong> for Delivery Details and on <i className='icon-link'></i>
        for Payment Details.
      </div>
      {rangeDates[0].startDate && rangeDates[0].endDate && (
        <p className='mb-0'>
          List delivery from{' '}
          <span className='text-orange fw-bold'>
            {moment(rangeDates[0].startDate).format('DD/MM/YYYY')}
          </span>{' '}
          to{' '}
          <span className='text-orange fw-bold'>
            {moment(rangeDates[0].endDate).format('DD/MM/YYYY')}
          </span>
        </p>
      )}
    </div>
  )

  const handleExport = (arrayDeliveryInfo) => {
    const listSheetName = []
    const wb = XLSX.utils.book_new()

    arrayDeliveryInfo.forEach((deliveryInfo) => {
      const rowsOfData = new Array(deliveryInfo.listOrderDelivery.length) || []
      deliveryInfo.listOrderDelivery.forEach((delivery, index) => {
        const { dzName, dzPrice, address, uName, uPhone, uEmail, status, comment, bookerStatus } =
          delivery

        const statusString =
          {
            [DELIVERY_HOST_CANCELLED]: 'Cancelled',
            [DELIVERY_HOST_UNPAID]: 'Not paid',
            [DELIVERY_HOST_PAID]: 'Paid',
          }[status] || ''

        const bookerStatusString =
          {
            [DELIVERY_BUYER_CANCELLED]: 'Cancelled',
            [DELIVERY_BUYER_REFUND]: 'Refund',
            [DELIVERY_BUYER_UNPAID]: 'Not paid',
            [DELIVERY_BUYER_PAID]: 'Paid',
          }[bookerStatus] || ''

        rowsOfData[index] = [
          dzName,
          `$${dzPrice}`,
          address,
          uName,
          uPhone,
          uEmail,
          statusString,
          bookerStatusString,
          comment,
        ]
      })

      const rows = [
        [
          'Zone name',
          'Zone price',
          'Address',
          'Buyer name',
          'Buyer phone',
          'Buyer email',
          'Status',
          'Booker Status',
          'Comment',
        ],
        ...rowsOfData,
      ]

      const fileName = normalizeName(
        `Delivery on ${convertTimeStringWithDuration(
          deliveryInfo.deliveryTime,
          deliveryInfo.deliveryDuration
        )}`
      )

      const formatSheetName = fileName.slice(0, 28).trim()
      let sheetAfterFormat = formatSheetName

      if (listSheetName.includes(formatSheetName)) {
        sheetAfterFormat = `(1)${formatSheetName}`
        let countItemIsDuplicate = 1
        while (listSheetName.includes(sheetAfterFormat)) {
          countItemIsDuplicate++
          sheetAfterFormat = `(${countItemIsDuplicate})${formatSheetName}`
        }
      }

      const ws = XLSX.utils.aoa_to_sheet(rows)
      XLSX.utils.book_append_sheet(wb, ws, sheetAfterFormat)
      listSheetName.push(sheetAfterFormat)
    })

    XLSX.writeFile(wb, 'Total order.xlsx')
  }

  const exportOrder = () => {
    const uniqueId = []
    const arrayDeliveryInfo = []
    const unique = listDeliveriesFiltered.filter((element) => {
      const isDuplicate = uniqueId.includes(element.did)
      if (!isDuplicate) {
        uniqueId.push(element.did)
        return true
      }
      return false
    })

    unique.forEach((delivery) => {
      arrayDeliveryInfo.push({
        id: delivery.did,
        deliveryDuration: delivery.dDeliveryDuration,
        deliveryTime: delivery.dDeliveryTime,
        listOrderDelivery: listDeliveriesFiltered.filter((item) => item.did === delivery.did),
      })
    })

    handleExport(arrayDeliveryInfo)
  }

  return (
    <div className='custom-scrollbar position-relative flex-grow-1'>
      <div className='container-fluid px-3 manage-delivery-booking'>
        {listDeliveriesFiltered && errorDeliveryAndDeliveryBooking ? (
          <div>No Delivery Found</div>
        ) : null}
        <Row className='my-3 align-items-center'>
          <Col>
            <span className='ps-3 fw-bold text-orange fs-2'>Manage Deliveries</span>
          </Col>
          <Col className='d-flex align-items-center text-uppercase justify-content-end'>
            <Button
              role='button'
              className='btn-export rounded-0 ms-3 shadow-none'
              outline
              onClick={exportOrder}
            >
              Export List
            </Button>
          </Col>
        </Row>
        <Row className='align-items-center'>
          <Col>
            <SearchDelivery
              searchString={searchString}
              onChange={onChangeSearch}
              styles={{ marginTop: 0 }}
            />
          </Col>
          <Col xs='4'>
            <SelectFilter
              handleChangeRangeDate={handleChangeRangeDate}
              handleChangePaymentStatus={handleChangePaymentStatus}
              filter={filter}
            />
          </Col>
          <Col>
            <Button
              role='button'
              outline
              className='btn btn-date rounded-0 ms-auto shadow-none'
              onClick={handleClearFilter}
            >
              Clear Filter
            </Button>
          </Col>
          <Col>
            <Pagination
              filter={filter}
              onHandlePrev={handlePrev}
              onHandleNext={handleNext}
              currentDeliveries={listDeliveriesFiltered}
            />
          </Col>
        </Row>
        <div className='row-top'>
          {showNote(rangeDate)}

          <DeliveryList listDelivery={listDeliveriesFiltered} setSorting={setSorting} />

          {/* {!loadingDelivery && listDeliveriesFiltered.length === 0 && (
                <div className='text-center fw-bold fs-6 my-3'>Have No Found Deliveries</div>
              )} */}
        </div>
      </div>
    </div>
  )
}

export default DeliveryBooking
