/* eslint-disable multiline-ternary */
import { DeviceUserContext } from '_context/DeviceUserContext'
import { getMyGroups } from '_redux/modules/group'
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { DESKTOP } from '_utils/constant'
import GroupItem from '../components/GroupItem'

const GroupList = () => {
  const dispatch = useDispatch()
  const { myGroups } = useSelector((state) => state.group)
  const { checkDeviceUser } = useContext(DeviceUserContext)

  useEffect(() => {
    dispatch(getMyGroups)
  }, [])

  if (myGroups === null || myGroups === undefined) return <div>Loading...</div>

  return (
    <div
      className='container group-page'
      style={checkDeviceUser === DESKTOP ? { height: 'auto' } : null}
    >
      <div className='row-top'>
        <div className='row mt-2'>
          <div className='col-6'>
            <h4 className='c-txt-14'>
              MY GROUP (<span>{myGroups?.length}</span>)
            </h4>
          </div>
          <div className='col-6 text-right'>
            <Link to='/group/create' className='link-share'>
              Create New
            </Link>
          </div>
        </div>
        <div className='group-list group-list--mygroup'>
          {myGroups.length !== 0 &&
            myGroups
              .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
              .map((item, index) => <GroupItem key={index} groupInfo={item} />)}
          {!myGroups.length && (
            <div className='fw-bold text-center mt-5'>You don&apos;t have any groups!!!</div>
          )}
        </div>
        <div className='text-center'>
          <Link to='/group/search' className='c-txt-15 link-share'>
            Search for a group to Join
          </Link>
        </div>
      </div>
    </div>
  )
}

export default GroupList
