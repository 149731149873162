import { convertToCurrentGMT, isDisableEvent } from '_utils/function'
import { getCreateEventData, getUserInfo, removeCreateEventData } from '_utils/localData'
import React, { useEffect, useMemo, useState } from 'react'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { Link, useHistory, withRouter } from 'react-router-dom'
import './style.scss'

const CREATE_EVENT_PATHS = [
  'create',
  'create-items',
  'created-confirm',
  'update',
  'update-items',
  'update-confirm',
]

const Footer = ({ location }) => {
  const history = useHistory()
  const userInfo = getUserInfo()

  // States
  const [pathname, setPathname] = useState(location.pathname.split('/'))
  const [totalMyPurchases, setTotalMyPurchases] = useState(0)

  // Selectors
  const { myEvents } = useSelector((state) => state.event)
  const { myOrders } = useSelector((state) => state.order)

  // orderDetail

  // Memoized values
  const isAllowHost = useMemo(
    () => userInfo?.groups?.some((group) => group.isHost === 1 || group.isAdmin === 1),
    [userInfo?.groups]
  )

  const activeOrders = useMemo(
    () =>
      myOrders.filter(
        (item) =>
          !isDisableEvent(
            item?.status,
            item?.buyerStatus,
            convertToCurrentGMT(item?.ePickupTime),
            item?.ePickupDuration
          )
      ),
    [myOrders]
  )

  const isEventPath = useMemo(
    () => pathname[1] === 'event' && CREATE_EVENT_PATHS.includes(pathname[2]?.split('?')[0]),
    [pathname]
  )

  // Effects
  useEffect(() => {
    setTotalMyPurchases(activeOrders.length)
  }, [activeOrders])

  useEffect(() => {
    setPathname(location.pathname.split('/'))
  }, [location])

  // Handlers
  const handleCreateEvent = () => {
    if (getCreateEventData()?.id) {
      removeCreateEventData()
    }
    history.push('/event/create')
  }

  // // Early return if no content
  // if (!totalMyPurchases && !myEvents?.length) {
  //   return null
  // }

  if (!isAllowHost) {
    return null
  }

  return (
    <footer className='footer footer-admin'>
      <div className='container'>
        <ul
          className={`menu ${
            !isAllowHost && !myEvents?.length && totalMyPurchases ? 'justify-content-center' : ''
          }`}
        >
          {isAllowHost && (
            <li>
              <a
                className={`${isEventPath ? 'active' : ''} cursor-pointer`}
                onClick={handleCreateEvent}
              >
                <i className='icon-host' />
                <span>Host</span>
              </a>
            </li>
          )}

          {!!totalMyPurchases && (
            <li>
              <Link
                to='/event/list-my-purchases'
                className={`step-4 ${
                  pathname[1] === 'event' && pathname[2] === 'list-my-purchases' ? 'active' : ''
                }`}
              >
                <div className='position-relative'>
                  <HiOutlineShoppingBag size={30} />
                  <span
                    id='totalMyPurchases'
                    style={{
                      position: 'absolute',
                      top: '61%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      color: '#7c797a'
                    }}
                  >
                    {totalMyPurchases}
                  </span>
                </div>
                My Purchase
              </Link>
            </li>
          )}

          {isAllowHost && (
            <li>
              <Link
                to='/event/list-my-events'
                className={
                  pathname[1] === 'event' && pathname[2] === 'list-my-events' ? 'active' : ''
                }
              >
                <i className='icon-host-event' />
                <span>Hosted Event {!!myEvents?.length && <span>({myEvents?.length})</span>}</span>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </footer>
  )
}

export default withRouter(Footer)
