import deliveryApi from '_api/delivery'
import { updateDeliveryInfo } from '_redux/modules/delivery'
import {
  DEFAULT_DELIVERY_OPTION,
  DELIVERY_BUYER_CANCELLED,
  DELIVERY_BUYER_PAID,
  DELIVERY_BUYER_REFUND,
  DELIVERY_BUYER_UNPAID,
  DELIVERY_CLOSED,
  DELIVERY_HOST_CANCELLED,
  DELIVERY_HOST_PAID,
  DELIVERY_HOST_UNPAID,
  DELIVERY_OPENED,
  LIST_TIME,
} from '_utils/constant'
import {
  convertTimeStringWithDuration,
  convertToCurrentGMT,
  convertToGMT0,
  exportToCsv,
  normalizeName,
} from '_utils/function'
import { getUserInfo } from '_utils/localData'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import useQuery from '../../../hooks/useQuery'
import DeliveryOptionsModal from '../components/DeliveryOptionsModal'

const validatePrice = (price) => price >= 0
const validateZoneName = (name) => name.trim() !== ''

const validateDeliveryZone = (zone) => {
  const errors = {}

  if (!validateZoneName(zone.name)) {
    errors.name = 'Name is required'
  }

  if (!validatePrice(zone.price)) {
    errors.price = 'Price must be greater than 0'
  }

  return errors
}

export default function NavBar({ handelBroadcast, deliveryInfo, deliveryShop }) {
  const {
    shopEventDeliveryBookings: shopEventListDelivery = [],
    deliveryBookings: listDelivery = [],
    deliveryTime,
    deliveryDuration,
    deliveryStatus,
    id,
  } = deliveryInfo

  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const params = useQuery()
  const userInfo = getUserInfo()
  if (!userInfo) {
    addToast('User info not found', { appearance: 'error', autoDismiss: true })
    return null
  }
  const { id: userId } = userInfo
  const shopIdParam = params.get('shopId')

  const [modal, setModal] = useState(false)
  const [message, setMessage] = useState('')
  const [openModalAddDelivery, setOpenModalAddDelivery] = useState(false)
  const [deliveryZones, setDeliveryZones] = useState([])
  const [errorMessageDeliveryZone, setErrorMessageDeliveryZone] = useState('')
  const [deliveryHourStart, setDeliveryHourStart] = useState('')
  const [deliveryHourEnd, setDeliveryHourEnd] = useState('')
  const [deliveryDate, setDeliveryDate] = useState(new Date())
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [zoneErrors, setZoneErrors] = useState({})

  useEffect(() => {
    if (!openModalAddDelivery) {
      setErrorMessageDeliveryZone('')
    }
  }, [openModalAddDelivery])

  const toggle = () => setModal(!modal)

  const toggleModalAddDelivery = () => {
    setOpenModalAddDelivery(!openModalAddDelivery)
  }

  const onChangeMessage = (e) => {
    setMessage(e.target.value)
  }

  const onCreateNotification = () => {
    handelBroadcast(message)
    toggle()
  }

  const onExportOrder = () => {
    let switchList = []

    if (deliveryShop) {
      switchList = shopEventListDelivery
    } else {
      switchList = listDelivery
    }

    const rowsOfData = new Array(switchList.length) || []
    for (let index = 0; index < switchList.length; index++) {
      const delivery = switchList[index]

      let statusString = ''
      let bookerStatusString = ''

      const {
        dzName,
        dzPrice,
        address,
        // orderReference,
        uName,
        uPhone,
        uEmail,
        status,
        comment,
        bookerStatus,
      } = delivery

      switch (status) {
        case DELIVERY_HOST_CANCELLED:
          statusString = 'Cancelled'
          break
        case DELIVERY_HOST_UNPAID:
          statusString = 'Not paid'
          break
        case DELIVERY_HOST_PAID:
          statusString = 'Paid'
          break
        default:
          break
      }

      switch (bookerStatus) {
        case DELIVERY_BUYER_CANCELLED:
          bookerStatusString = 'Cancelled'
          break
        case DELIVERY_BUYER_REFUND:
          bookerStatusString = 'Refund'
          break
        case DELIVERY_BUYER_UNPAID:
          bookerStatusString = 'Not paid'
          break
        case DELIVERY_BUYER_PAID:
          bookerStatusString = 'Paid'
          break
        default:
          break
      }

      rowsOfData[index] = [
        dzName,
        `$${dzPrice}`,
        address,
        // orderReference,
        uName,
        uPhone,
        uEmail,
        statusString,
        bookerStatusString,
        comment,
      ]
    }

    const rows = [
      [
        'Zone name',
        'Zone price',
        'Address',
        // 'Order ref',
        'Buyer name',
        'Buyer phone',
        'Buyer email',
        'Status',
        'Booker Status',
        'Comment',
      ],
      ...rowsOfData,
    ]

    const fileName = normalizeName(
      `Delivery on ${convertTimeStringWithDuration(deliveryTime, deliveryDuration)}`
    )
    exportToCsv(`${fileName}.csv`, rows)
  }

  const onUpdateStatusDelivery = async (close = false) => {
    try {
      const r = confirm('Are you sure?')
      if (r === true) {
        let data = {}
        if (close) {
          data = { deliveryStatus: DELIVERY_CLOSED }
        } else {
          data = { deliveryStatus: DELIVERY_OPENED }
        }
        await deliveryApi.updateDeliveryStatus(id, data, deliveryShop)
        dispatch(updateDeliveryInfo(id, data))
        addToast('Updated successfully', { appearance: 'success', autoDismiss: true })
      }
    } catch (error) {
      const { msgResp } = error
      addToast(msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const onChangeZoneDelivery = (index, type, value) => {
    const updatedZones = [...deliveryZones]
    updatedZones[index][type] = value
    setDeliveryZones(updatedZones)

    // Update errors only for the changed zone
    const errors = { ...zoneErrors }
    errors[index] = validateDeliveryZone(updatedZones[index])
    setZoneErrors(errors)
  }

  const hasValidationErrors = () =>
    deliveryZones.some((zone) => {
      const errors = validateDeliveryZone(zone)
      return Object.keys(errors).length > 0
    })

  const handleOnClickOk = async () => {
    setIsSubmitting(true)
    const deliveryStartIndex = LIST_TIME.findIndex((item) => item.text === deliveryHourStart)
    const deliveryEndIndex = LIST_TIME.findIndex((item) => item.text === deliveryHourEnd)
    const timeStart = LIST_TIME[deliveryStartIndex].value
    const timeEnd = LIST_TIME[deliveryEndIndex].value
    const deliveryDur = (timeEnd - timeStart) * 3600
    if (deliveryDur <= 0) {
      addToast('Invalid delivery time duration', { appearance: 'error', autoDismiss: true })
      return
    }

    const rememberedDeliveryZones = deliveryZones
      .map(({ name, price }) => ({ name, price }))
      .filter((item) => item.name && item.price >= 0)

    if (!rememberedDeliveryZones.length) {
      setErrorMessageDeliveryZone('Please fill in all delivery zones')
      setIsSubmitting(false)
      return
    }

    if (hasValidationErrors()) {
      setIsSubmitting(false)
      return
    }

    const deliveryTimeLocal = `${moment(deliveryDate).format('DD-MM-YYYY')} ${timeStart}:00`
    const deliveryTimeGMT0 = convertToGMT0(deliveryTimeLocal)

    try {
      await deliveryApi.updateDeliveryByHost(
        id,
        { deliveryTime: deliveryTimeGMT0, deliveryDuration: deliveryDur },
        deliveryShop
      )

      await deliveryApi.updateRememberedDeliveryZones(
        userId,
        { rememberedDeliveryZones },
        deliveryShop,
        shopIdParam
      )

      dispatch(
        updateDeliveryInfo(id, {
          deliveryTime: deliveryTimeGMT0,
          deliveryDuration: deliveryDur,
        })
      )

      addToast('Updated successfully', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      setIsSubmitting(false)
      addToast(error?.msgResp, { appearance: 'error', autoDismiss: true })
      return
    }
    setIsSubmitting(false)
    toggleModalAddDelivery()
  }

  const onChangeTimeDelivery = (e) => {
    setDeliveryDate(e)
  }

  const handleAddMoreDeliveryZone = () => {
    setDeliveryZones(JSON.parse(JSON.stringify([...deliveryZones, DEFAULT_DELIVERY_OPTION])))
  }

  const onEditDelivery = async () => {
    const { msgResp } = await deliveryApi.getRememberedDeliveryZones(shopIdParam, deliveryShop)

    if (msgResp.length > 0) {
      setDeliveryZones(msgResp)
    }
    const deliveryTimeCurrentGMT = convertToCurrentGMT(deliveryTime)
    const timeStart = Number(deliveryTimeCurrentGMT.split(' ')[1].split(':')[0])
    const timeEnd = timeStart + deliveryDuration / 3600
    const deliveryTimeSplit = deliveryTimeCurrentGMT.split(' ')[0].split('-').reverse()
    setDeliveryDate(new Date(deliveryTimeSplit[0], deliveryTimeSplit[1] - 1, deliveryTimeSplit[2]))
    const indexStart = LIST_TIME.findIndex((item) => item.id === timeStart)
    const indexEnd = LIST_TIME.findIndex((item) => item.id === timeEnd)
    setDeliveryHourStart(LIST_TIME[indexStart]?.text)
    setDeliveryHourEnd(LIST_TIME[indexEnd]?.text)
    toggleModalAddDelivery()
  }

  const handleOnRemoveDeliveryZone = (index) => {
    deliveryZones.splice(index, 1)
    setDeliveryZones(JSON.parse(JSON.stringify([...deliveryZones])))
  }

  // const disabledStyle = {
  //   pointerEvents: 'none',
  //   opacity: 0.5,
  //   filter: 'grayscale(100%)',
  //   backgroundColor: '#f1f1f1',
  //   color: '#b3b3b3',
  // }

  return (
    <>
      <ul className='nav-tab'>
        <li onClick={onEditDelivery}>Edit</li>
        <li onClick={toggle}>Broadcast</li>
        {deliveryStatus === DELIVERY_CLOSED ? (
          <li onClick={() => onUpdateStatusDelivery(false)}>Re-open Delivery</li>
        ) : (
          <li onClick={() => onUpdateStatusDelivery(true)}>Close Delivery</li>
        )}
        <li onClick={onExportOrder}>Export Delivery</li>
        <Modal isOpen={modal} toggle={toggle} centered>
          <ModalHeader className='modal-header-created' style={{ margin: '0 auto' }}>
            <span className='c-txt-gra'>Message to Broadcast?</span>
          </ModalHeader>
          <ModalBody>
            <div className='c-form'>
              <div className='form-group'>
                <input
                  type='text'
                  value={message}
                  className='form-control form-control--radius'
                  onChange={onChangeMessage}
                ></input>
              </div>
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
            <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
              <button className='btn btn--solid btn--solid04' onClick={toggle}>
                CANCEL
              </button>
            </div>
            <div className='col-6 px-0 m-0' style={{ flexBasis: '48%' }}>
              <button className='btn btn--org btn--org02' onClick={onCreateNotification}>
                OK
              </button>
            </div>
          </ModalFooter>
        </Modal>

        {openModalAddDelivery && (
          <DeliveryOptionsModal
            modal={openModalAddDelivery}
            toggle={toggleModalAddDelivery}
            handleOnClickOk={handleOnClickOk}
            deliveryZones={deliveryZones}
            deliveryTime={deliveryDate}
            onChangeZoneDelivery={onChangeZoneDelivery}
            onChangeTimeDelivery={onChangeTimeDelivery}
            handleAddMoreDeliveryZone={handleAddMoreDeliveryZone}
            deliveryHourStart={deliveryHourStart}
            setDeliveryHourStart={setDeliveryHourStart}
            deliveryHourEnd={deliveryHourEnd}
            setDeliveryHourEnd={setDeliveryHourEnd}
            isSubmitting={isSubmitting}
            handleOnRemoveDeliveryZone={handleOnRemoveDeliveryZone}
            errorMessageDeliveryZone={errorMessageDeliveryZone}
            zoneErrors={zoneErrors}
            hasValidationErrors={hasValidationErrors}
          />
        )}
      </ul>
    </>
  )
}
