import deliveryApi from '_api/delivery'
import useQuery from '_hooks/useQuery'
import {
  clearDeliveryByBuyer,
  clearListDeliveryAndDeliveryBooking,
  getDeliveryDetail,
  updateDeliveryBookerStatus,
  updateDeliveryDetail,
} from '_redux/modules/delivery'
import { clearDataPurchaseDetail, clearMyOrder } from '_redux/modules/order'
import { updateRememberedDeliveryAddress } from '_redux/modules/user'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
// import uploadApi from '_api/upload'
import {
  DELIVERY_BUYER_CANCELLED,
  DELIVERY_BUYER_PAID,
  DELIVERY_BUYER_UNPAID,
  DELIVERY_HOST_CANCELLED,
  DELIVERY_HOST_PAID,
  DELIVERY_HOST_UNPAID,
} from '_utils/constant'
import { convertTimeStringWithDuration, convertToCurrentGMT } from '_utils/function'
import Cancel from '../components/Cancel'
import DeliveryForm from '../components/DeliveryForm'
import Payment from '../components/Payment'
import PaymentConfirmed from '../components/PaymentConfirmed'
import PendingConfirm from '../components/PendingConfirm'

const now = moment().unix()

export default function DeliveryDetail() {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const params = useQuery()
  const history = useHistory()
  const refDelivery = params.get('ref')
  const shopId = params.get('shopId')
  const deliveryShop = !!shopId
  const photoRef = useRef()

  const [isLoading, setIsLoading] = useState(true)

  const {
    currentDeliveryDetail,
    errorGetDeliveryDetail,
    listDeliveryCreatedByHost = [],
  } = useSelector((state) => state.delivery)

  const {
    ref,
    did,
    dzName,
    address,
    comment,
    paymentScreenshot,
    bookerStatus,
    status,
    dCreatedUserId,
    dDeliveryTime,
    dCreatedUserPhotoUrl,
    dCreatedUserPhone,
    dCreatedUserName,
    dGroupId,
    deliveryZones,
  } = currentDeliveryDetail || {}

  let { listDeliveryCreated = null } =
    listDeliveryCreatedByHost.find((item) => item.hostId === dCreatedUserId) || {}

  listDeliveryCreated = listDeliveryCreated
    ? listDeliveryCreated
        .sort((a, b) => b.deliveryTimestamp - a.deliveryTimestamp)
        .filter((item) => item.groupId === dGroupId && item.deliveryTimestamp > now)
        .map((item) => ({
          ...item,
          deliveryTime: convertTimeStringWithDuration(item.deliveryTime, item.deliveryDuration),
        }))
    : null

  const [dZone, setDZone] = useState(dzName)
  const [deliveryTime, setDeliveryTime] = useState(did)
  const [dZoneDetail, setDZoneDetail] = useState({})
  const [dAddress, setDAddress] = useState(address)
  const [dOldAddress, setDOldAddress] = useState(address)
  const [dComment, setDComment] = useState(comment)
  const [dOldComment, setDOldComment] = useState(comment)
  const [showDPayment, setShowDPayment] = useState(false)
  const [showDPendingConfirm, setShowDPendingConfirm] = useState(false)
  const [showDPaymentConfirmed, setShowDPaymentConfirmed] = useState(false)
  const [showDCancelled, setShowDCancelled] = useState(false)
  // const [paymentFileUpload, setPaymentFileUpload] = useState('')
  const [paymentFileUrl, setPaymentFileUrl] = useState(paymentScreenshot)
  const [isSubmittedPayment, setIsSubmittedPayment] = useState(false)
  const [bookerStatusState, setBookerStatusState] = useState(bookerStatus)
  const [errorMessage, setErrorMessage] = useState({ deliveryZone: '', deliveryAddress: '' })
  const [disabledInput, setDisabledInput] = useState(false)
  const [showCancelButton, setShowCancelButton] = useState(false)

  const initialDelivery = async (_refDelivery) => {
    setIsLoading(true)
    await dispatch(getDeliveryDetail(_refDelivery, shopId))
    setIsLoading(false)
  }

  useEffect(() => {
    setDZone(dzName)
    setDeliveryTime(did)
    setDAddress(address)
    setDOldAddress(address)
    setDComment(comment)
    setDOldComment(comment)
    if (dzName && deliveryZones && !!deliveryZones.length > 0) {
      setDZoneDetail(deliveryZones.find((item) => item.name === dzName))
    }
  }, [dzName, address, comment, did])

  useEffect(() => {
    setPaymentFileUrl(paymentScreenshot)
  }, [paymentScreenshot])

  useEffect(() => {
    setShowDPayment(false)
    setShowDPendingConfirm(false)
    setShowDPaymentConfirmed(false)
    setShowDCancelled(false)
    setDisabledInput(false)
    setShowCancelButton(false)

    setBookerStatusState(bookerStatus)

    if (bookerStatus > DELIVERY_BUYER_UNPAID) {
      setDisabledInput(true)
    }

    if (bookerStatus === DELIVERY_BUYER_CANCELLED || status === DELIVERY_HOST_CANCELLED) {
      setShowDCancelled(true)
    } else if (bookerStatus === DELIVERY_BUYER_UNPAID && status === DELIVERY_HOST_UNPAID) {
      setShowDPayment(true)
      setShowCancelButton(true)
    } else if (bookerStatus === DELIVERY_BUYER_PAID && status === DELIVERY_HOST_UNPAID) {
      setShowDPendingConfirm(true)
      setShowCancelButton(false)
    } else if (bookerStatus === DELIVERY_BUYER_PAID && status === DELIVERY_HOST_PAID) {
      setShowDPaymentConfirmed(true)
      setShowCancelButton(false)
    }
  }, [bookerStatus, status])

  useEffect(() => {
    initialDelivery(refDelivery)
  }, [refDelivery, bookerStatusState])

  const onChangeDTime = async (e) => {
    setDeliveryTime(e.target.value)
    const data = { deliveryId: e.target.value }
    try {
      await deliveryApi.updateDeliveryBooking(refDelivery, data, deliveryShop)
      dispatch(updateDeliveryDetail(refDelivery, data))
      dispatch(clearDeliveryByBuyer())
      dispatch(clearListDeliveryAndDeliveryBooking())
      addToast('Updated successfully!', { appearance: 'success', autoDismiss: true })
      setErrorMessage({ deliveryZone: '', deliveryAddress: '' })
    } catch (error) {
      addToast(error?.msgCode, { appearance: 'error', autoDismiss: true })
    }
  }

  const onChangeDZone = async (e) => {
    setDZone(e.target.value)
    setDZoneDetail(deliveryZones.find((item) => item.name === e.target.value))
    if (!e.target.value) {
      setErrorMessage({ ...errorMessage, deliveryZone: 'Delivery location cannot be blank' })
      return
    }
    const data = { deliveryZone: deliveryZones.find((item) => item.name === e.target.value) }
    try {
      await deliveryApi.updateDeliveryBooking(refDelivery, data, deliveryShop)
      dispatch(
        updateDeliveryDetail(refDelivery, {
          deliveryZone: data.deliveryZone,
          dzName: data.deliveryZone.name,
          dzPrice: data.deliveryZone.price,
        })
      )
      dispatch(clearListDeliveryAndDeliveryBooking())
      addToast('Updated successfully!', { appearance: 'success', autoDismiss: true })
      setErrorMessage({ deliveryZone: '', deliveryAddress: '' })
    } catch (error) {
      addToast(error?.msgCode, { appearance: 'error', autoDismiss: true })
    }
  }
  const onChangeDAddress = async (e) => {
    setDAddress(e.target.value)
  }
  const onChangeDComment = async (e) => {
    setDComment(e.target.value)
  }

  const onBlurDAddress = async () => {
    if (!dAddress) {
      setErrorMessage({ ...errorMessage, deliveryAddress: 'Delivery address cannot be blank' })
      return
    }
    if (dAddress?.trim() !== dOldAddress?.trim()) {
      const data = { address: dAddress }
      try {
        await deliveryApi.updateDeliveryBooking(refDelivery, data, deliveryShop)

        await deliveryApi.updateRememberedDeliveryAddress(
          { rememberedDeliveryZones: dAddress },
          deliveryShop
        )
        dispatch(updateRememberedDeliveryAddress(dAddress))
        dispatch(updateDeliveryDetail(refDelivery, data))
        dispatch(clearListDeliveryAndDeliveryBooking())
        addToast('Updated successfully!', { appearance: 'success', autoDismiss: true })
        setErrorMessage({ deliveryZone: '', deliveryAddress: '' })
        setDOldAddress(dAddress)
      } catch (error) {
        addToast(error?.msgCode, { appearance: 'error', autoDismiss: true })
      }
    }
  }
  const onBlurDComment = async () => {
    if (dComment?.trim() !== dOldComment?.trim()) {
      const data = { comment: dComment }
      try {
        await deliveryApi.updateDeliveryBooking(refDelivery, data, deliveryShop)
        dispatch(updateDeliveryDetail(refDelivery, data))
        dispatch(clearListDeliveryAndDeliveryBooking())
        addToast('Updated successfully!', { appearance: 'success', autoDismiss: true })
        setDOldComment(dComment)
      } catch (error) {
        addToast(error?.msgCode, { appearance: 'error', autoDismiss: true })
      }
    }
  }

  const onUserImageUploaded = (_event) => {
    const { files } = _event.target
    if (files) {
      const img = files[0]
      setPaymentFileUrl(URL.createObjectURL(img))
      // setPaymentFileUpload(files)
    }
  }

  const handleCancel = async () => {
    try {
      await deliveryApi.updateDeliveryBookerStatus(
        refDelivery,
        {
          bookerStatus: DELIVERY_BUYER_CANCELLED,
        },
        deliveryShop
      )
      dispatch(clearDeliveryByBuyer())
      dispatch(updateDeliveryBookerStatus(refDelivery, DELIVERY_BUYER_CANCELLED))
      dispatch(clearListDeliveryAndDeliveryBooking())
      addToast('Delivery cancelled!', { appearance: 'success', autoDismiss: true })
      history.push('/event/list-my-purchases')
    } catch (e) {
      addToast(e.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const confirmPayment = async (e) => {
    e.preventDefault()
    const url = paymentFileUrl || photoRef.current
    setIsSubmittedPayment(true)
    // if (paymentFileUpload) {
    //   const { msgResp: res } = await uploadApi.uploadPhoto(paymentFileUpload)
    //   // eslint-disable-next-line prefer-destructuring
    //   url = res.url
    // }
    if (url) {
      try {
        await deliveryApi.updateDeliveryBookerStatus(
          refDelivery,
          {
            paymentScreenshot: url,
            bookerStatus: DELIVERY_BUYER_PAID,
          },
          deliveryShop
        )
        dispatch(updateDeliveryBookerStatus(refDelivery, DELIVERY_BUYER_PAID))
        dispatch(clearMyOrder())
        dispatch(clearDeliveryByBuyer())
        dispatch(clearDataPurchaseDetail())
        dispatch(clearListDeliveryAndDeliveryBooking())
        setBookerStatusState(DELIVERY_BUYER_PAID)
      } catch (error) {
        setIsSubmittedPayment(false)
        addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
      }
    } else {
      setIsSubmittedPayment(false)
      alert('Please upload payment screenshot')
    }
  }

  return (
    <div className='pb-3'>
      {isLoading ? <div>Loading...</div> : null}
      {!isLoading && errorGetDeliveryDetail ? <div>No Delivery Found</div> : null}
      {!isLoading && !errorGetDeliveryDetail ? (
        <>
          <DeliveryForm
            deliveryZones={deliveryZones}
            deliveryTime={deliveryTime}
            createdUserName={dCreatedUserName}
            createdUserPhotoUrl={dCreatedUserPhotoUrl}
            dAddress={dAddress}
            dComment={dComment}
            dZone={dZone}
            onChangeDZone={onChangeDZone}
            onChangeDTime={onChangeDTime}
            onChangeDAddress={onChangeDAddress}
            onChangeDComment={onChangeDComment}
            onBlurDAddress={onBlurDAddress}
            onBlurDComment={onBlurDComment}
            errorMessage={errorMessage}
            handleCancel={handleCancel}
            disabledInput={disabledInput}
            listDeliveryTime={listDeliveryCreated}
          />

          {showCancelButton ? (
            <div className='d-flex justify-content-center'>
              <div className='text-cancel-delivery' onClick={handleCancel}>
                Cancel Delivery
              </div>
            </div>
          ) : null}

          {showDPayment ? (
            <Payment
              onUserImageUploaded={onUserImageUploaded}
              paymentFileUrl={paymentFileUrl}
              confirmPayment={confirmPayment}
              final={dZoneDetail?.price}
              phone={dCreatedUserPhone}
              pickupTime={convertToCurrentGMT(dDeliveryTime)}
              orderRef={ref}
              stringRefNumber={`${ref?.substring(0, 4)}`}
              isSubmittedPayment={isSubmittedPayment}
              ref={photoRef}
            />
          ) : null}
          {showDPendingConfirm ? <PendingConfirm /> : null}
          {showDCancelled ? <Cancel /> : null}
          {showDPaymentConfirmed ? <PaymentConfirmed /> : null}
        </>
      ) : null}
    </div>
  )
}
