import {
  DELIVERY_BUYER_CANCELLED,
  DELIVERY_BUYER_PAID,
  DELIVERY_BUYER_UNPAID,
  DELIVERY_HOST_CANCELLED,
  DELIVERY_HOST_PAID,
  DELIVERY_HOST_UNPAID,
} from '_utils/constant'
import { getDateRemainFromNow } from '_utils/function'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FaTruck } from 'react-icons/fa'

import ButtonPayment from './ButtonPayment'
import ButtonPendingConfirmation from './ButtonPendingConfirmation'
import ButtonVerify from './ButtonVerify'

export default function ItemDeliveryBooking(props) {
  const {
    isDisable = false,
    createdUserPhotoUrl,
    createdUserName,
    groupName,
    shopName,
    deliveryTime,
    deliveryTimeString,
    status,
    bookerStatus,
    onClick = () => {},
  } = props

  const [showButtonPayment, setShowButtonPayment] = useState(false)
  const [showButtonPending, setShowButtonPending] = useState(false)
  const [showButtonVerify, setShowButtonVerify] = useState(false)

  useEffect(() => {
    setShowButtonPayment(false)
    setShowButtonVerify(false)
    setShowButtonPending(false)

    if (bookerStatus !== DELIVERY_BUYER_CANCELLED && status !== DELIVERY_HOST_CANCELLED) {
      if (bookerStatus === DELIVERY_BUYER_UNPAID && status === DELIVERY_HOST_UNPAID) {
        setShowButtonPayment(true)
      } else if (bookerStatus === DELIVERY_BUYER_PAID && status === DELIVERY_HOST_UNPAID) {
        setShowButtonPending(true)
      } else if (bookerStatus === DELIVERY_BUYER_PAID && status === DELIVERY_HOST_PAID) {
        setShowButtonVerify(true)
      }
    }
  }, [bookerStatus, status])

  return (
    <div
      className={`group-list__item ${isDisable ? 'is-closed' : ''}`}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <div className='group-list__img'>
        <div className='c-image'>
          <a href='' onClick={(e) => e.preventDefault()}>
            <img
              src={createdUserPhotoUrl || process.env.REACT_APP_AVATAR_URL + createdUserName}
              style={isDisable ? { filter: 'grayscale(100%)' } : {}}
              alt=''
            />
          </a>
        </div>
      </div>
      <div className='group-list__info'>
        <h3 style={isDisable ? { color: 'gray !important' } : {}}>
          <a href='' onClick={(e) => e.preventDefault()}>
            {createdUserName}
          </a>
        </h3>
        <p>{groupName || shopName}</p>
      </div>
      <div className='group-list__btn text-right' style={{ lineHeight: '1rem' }}>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center justify-content-end'>
            <div className='d-flex flex-column mr-1'>
              <div>{deliveryTimeString[0]}</div>
              <div>{deliveryTimeString[1]}</div>
            </div>
            <div style={{ fontSize: '1.5rem', color: 'rgb(7, 157, 154)' }}>
              <FaTruck />
            </div>
          </div>
          <div>
            <span style={{ fontSize: '0.6875rem' }}>{`Delivery in ${getDateRemainFromNow(
              deliveryTime
            )}`}</span>
          </div>
        </div>
      </div>
      <div className='group-list__des'>
        {showButtonPayment ? <ButtonPayment isDisable={isDisable} /> : null}
        {showButtonPending ? <ButtonPendingConfirmation isDisable={isDisable} /> : null}
        {showButtonVerify ? <ButtonVerify isDisable={isDisable} /> : null}
      </div>
    </div>
  )
}

ItemDeliveryBooking.propTypes = {
  createdUserPhotoUrl: PropTypes.string,
  createdUserName: PropTypes.string,
  groupName: PropTypes.string,
  deliveryTime: PropTypes.string,
  onClick: PropTypes.func,
}
