import {
  ALL_EVENTS_OPENING,
  CREATE_EVENT_DATA,
  DATA_FIREBASE,
  DEBUG_MODE,
  DEBUG_SCOPE,
  DEVICE_USER,
  GROUP_ID_SELECTED,
  LIST_EVENT,
  LIST_GROUP,
  LIST_MY_EVENT,
  LIST_ORDER,
  LIST_PUBLIC_GROUP,
  LIST_SHOP_PAID,
  MODAL_INFO_CREATE_GROUP,
  SHOP_CACHE_KEY,
  SOCIAL_TOKEN,
  USER_INFO,
  USER_TOKEN,
} from './constant'

export const getShopCache = () => {
  const value = localStorage.getItem(SHOP_CACHE_KEY)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const setShopCache = (value) => {
  localStorage.setItem(SHOP_CACHE_KEY, JSON.stringify(value))
}

export const removeIsShareGroup = () => {
  localStorage.removeItem('isShareGroup')
}

export const getIsShareGroup = () => {
  const value = localStorage.getItem('isShareGroup')
  return value
}

// input value: string
export const setIsShareGroup = (value) => {
  localStorage.setItem('isShareGroup', value)
}

export const getModalInfoCreateGroup = () => {
  const value = localStorage.getItem(MODAL_INFO_CREATE_GROUP)

  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const setModalInfoCreateGroup = (modalInfo) => {
  localStorage.setItem(MODAL_INFO_CREATE_GROUP, JSON.stringify(modalInfo))
}

export const setUserToken = (value) => {
  localStorage.setItem(USER_TOKEN, JSON.stringify(value))
}

export const setGroupIdSelected = (value) => {
  localStorage.setItem(GROUP_ID_SELECTED, value)
}

export const getGroupIdSelected = () => localStorage.getItem(GROUP_ID_SELECTED)

export const removeGroupIdSelected = () => localStorage.removeItem(GROUP_ID_SELECTED)

export const getUserToken = () => {
  const value = localStorage.getItem(USER_TOKEN)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const removeUserToken = () => {
  localStorage.removeItem(USER_TOKEN)
}

export const setUserInfo = (value) => {
  localStorage.setItem(USER_INFO, JSON.stringify(value))
}

export const getUserInfo = () => {
  const value = localStorage.getItem(USER_INFO)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const removeUserInfo = () => {
  localStorage.removeItem(USER_INFO)
}

export const setSocialToken = (value) => {
  localStorage.setItem(SOCIAL_TOKEN, JSON.stringify(value))
}

export const getSocialToken = () => {
  const value = localStorage.getItem(SOCIAL_TOKEN)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const removeSocialToken = () => {
  localStorage.removeItem(SOCIAL_TOKEN)
}

export const setListGroup = (value) => {
  localStorage.setItem(LIST_GROUP, JSON.stringify(value))
}

export const getListGroup = () => {
  const value = localStorage.getItem(LIST_GROUP)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const removeListGroup = () => {
  localStorage.removeItem(LIST_GROUP)
}

export const setListPublicGroups = (value) => {
  localStorage.setItem(LIST_PUBLIC_GROUP, JSON.stringify(value))
}

export const getListPublicGroups = () => {
  const value = localStorage.getItem(LIST_PUBLIC_GROUP)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const setListEvents = (value) => {
  localStorage.setItem(LIST_EVENT, JSON.stringify(value))
}

export const getListEvents = () => {
  const value = localStorage.getItem(LIST_EVENT)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const setListMyEvents = (value) => {
  localStorage.setItem(LIST_MY_EVENT, JSON.stringify(value))
}

export const getListMyEvents = () => {
  const value = localStorage.getItem(LIST_MY_EVENT)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const setListOrders = (value) => {
  localStorage.setItem(LIST_ORDER, JSON.stringify(value))
}

export const getListOrders = () => {
  const value = localStorage.getItem(LIST_ORDER)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}
export const setDataFirebase = (value) => {
  localStorage.setItem(DATA_FIREBASE, JSON.stringify(value))
}

export const getDataFirebase = () => {
  const value = localStorage.getItem(DATA_FIREBASE)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const setListOrder = (value) => {
  localStorage.setItem(LIST_ORDER, JSON.stringify(value))
}

export const setCreateEventData = (value) => {
  localStorage.setItem(CREATE_EVENT_DATA, JSON.stringify(value))
}

export const getCreateEventData = () => {
  const value = localStorage.getItem(CREATE_EVENT_DATA)
  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const removeCreateEventData = () => {
  localStorage.removeItem(CREATE_EVENT_DATA)
}

export const setDebugMode = (isDebug) => {
  localStorage.setItem(DEBUG_MODE, isDebug)
}

export const getDebugMode = () => localStorage.getItem(DEBUG_MODE)

export const setDebugScope = (userEmails) => {
  localStorage.setItem(DEBUG_SCOPE, userEmails)
}

export const getDebugScope = () => localStorage.getItem(DEBUG_SCOPE)
export const setDeviceUser = (value) => localStorage.setItem(DEVICE_USER, value)
export const getDeviceUser = () => localStorage.getItem(DEVICE_USER)

export const setDomain = (key, value) => {
  localStorage.setItem(key, value)
}
export const getDomain = (key) => localStorage.getItem(key)

export const setListShopPaid = (data) => {
  localStorage.setItem(LIST_SHOP_PAID, JSON.stringify(data))
}

export const getListShopPaid = () => {
  const value = localStorage.getItem(LIST_SHOP_PAID)

  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const setAllEventsOpeningLocal = (data) => {
  localStorage.setItem(ALL_EVENTS_OPENING, JSON.stringify(data))
}

export const getAllEventsOpeningLocal = () => {
  const value = localStorage.getItem(ALL_EVENTS_OPENING)

  try {
    if (value) {
      return JSON.parse(value)
    }
    return null
  } catch (error) {
    return value
  }
}

export const clearAllEventsOpeningLocal = () => localStorage.removeItem(ALL_EVENTS_OPENING)
