import eventApi from '_api/event'
import { deleteScheduledEvent } from '_redux/modules/event'
import { convertToCurrentGMT } from '_utils/function'
import { setCreateEventData } from '_utils/localData'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { getScheduledEvent } from '../../../redux/modules/event'
import ModalConfirm from './ModalConfirm'

const MyScheduledEvent = ({ event }) => {
  const history = useHistory()
  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const [modalConfirm, setModalConfirm] = useState(false)

  const toggleModalConfirm = () => setModalConfirm(!modalConfirm)

  const getEvent = async (eid, edit = false) => {
    if (!eid) return
    try {
      let eventDetail = {}
      const res = await eventApi.getScheduledEvent(eid)
      eventDetail = res.msgResp
      if (eventDetail && eventDetail.id) {
        const { closingTime, pickupTime, openTime } = eventDetail

        const closingTimeCurrentGMT = convertToCurrentGMT(closingTime)

        const pickupTimeCurrentGMT = convertToCurrentGMT(pickupTime)

        const openTimeCurrentGMT = convertToCurrentGMT(openTime)

        eventDetail = {
          ...eventDetail,
          closingTime: closingTimeCurrentGMT,
          pickupTime: pickupTimeCurrentGMT,
          openTime: openTimeCurrentGMT,
        }
        if (!edit) {
          delete eventDetail.id
          delete eventDetail.deliveryStatus
          eventDetail.deliveryCost = 0
          eventDetail.discount = 0
          setCreateEventData(eventDetail)
          history.push('/event/create')
        } else {
          setCreateEventData(eventDetail)
          history.push(`/event/update?id=${eventDetail?.id}`)
        }
      }
    } catch (error) {
      addToast(error?.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  const handleRehostEvent = () => {
    if (!event.id) return
    getEvent(event.id)
  }
  const handleEditEvent = () => {
    if (!event.id) return
    getEvent(event.id, true)
  }

  const deleteScheduleEvent = async () => {
    try {
      await eventApi.deleteScheduledEvent(event.id)
      dispatch(deleteScheduledEvent(event.id))
      addToast('Deleted successfully', { appearance: 'success', autoDismiss: true })
      dispatch(getScheduledEvent)
    } catch (e) {
      addToast(e?.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  return (
    <div
      className='event-item group-list group-list--event group-list--event01 scheduled-event'
      style={{ cursor: 'default' }}
    >
      <div className='group-list__item scheduled-event'>
        <div className='group-list__info'>
          <h4>{event.title}</h4>
          <p className='paragraph-pre-wrap' style={{ cursor: 'default' }}>
            {event?.description}
          </p>
        </div>
        <div className='group-list__btn text-right scheduled-event-right'>
          <div className='scheduled-event-text-right'>
            Scheduled on{' '}
            {moment(convertToCurrentGMT(event?.openTime), 'DD-MM-YYYY').format('DD-MM-YYYY')}
          </div>
        </div>
      </div>

      <ul className='nav-tab'>
        <li onClick={handleEditEvent}>Edit Post</li>
        <li onClick={handleRehostEvent}>Re-host</li>
        <li onClick={toggleModalConfirm}>Delete</li>
      </ul>

      <ModalConfirm
        modal={modalConfirm}
        toggleModal={toggleModalConfirm}
        handleConfirm={deleteScheduleEvent}
        title='schedule event'
      />
    </div>
  )
}

MyScheduledEvent.propTypes = {
  event: PropTypes.object,
  setTimeGetScheduleEvent: PropTypes.func,
}

export default MyScheduledEvent
