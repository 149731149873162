import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useToasts } from 'react-toast-notifications'

import eventApi from '_api/event'
import userApi from '_api/user'
import useQuery from '_hooks/useQuery'
import { deleteEvent, getEventAndOrder } from '_redux/modules/event'
import { removeEventInGroup } from '_redux/modules/group'
import { deleteOrder } from '_redux/modules/order'
import {
  BUYER_ORDER_CANCELED,
  BUYER_PAID,
  DESKTOP,
  HOST_ORDER_CANCELED,
  HOST_ORDER_VERIFIED,
  PAYNOW,
} from '_utils/constant'
import { convertTimestampToCurrentGMT, convertToCurrentGMT, handleDataOrder } from '_utils/function'
import { getDeviceUser, getUserInfo } from '_utils/localData'

import { isNumber } from 'lodash'
import BoxPrice from './BoxPrice'
import EventInfo from './EventInfo'
import NavBar from './NavBar'
import OrderList from './OrderList'
import PayLater from './PayLater'
import OrderSearch from './SearchOrder'
import './style.scss'

function ManageOrder() {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const history = useHistory()
  const params = useQuery()
  const isByAdmin = params.get('isByAdmin') === 'true'

  const eventId = params.get('eventId')
  const isEventShop = params.get('eventShop')
  const { eventAndOrderById, errorGetEventAndOrder } = useSelector((state) => state.event)

  const {
    title,
    closingTimestamp,
    pickupTimestamp,
    closingTime,
    adminCost,
    discount,
    deliveryCost,
    pickupTime,
    id,
    shareCode,
    orders,
    productIdList = [],
    shopEventProductIdList = [],
    status,
    payable,
    paynow,
    groupId,
    totalOrderCount,
  } = eventAndOrderById.find((item) => item.id === eventId) || {}

  const { listOrder, totalAllOrder, adminCostAllOrder, paymentCollected } =
    productIdList.length > 0 || shopEventProductIdList.length > 0
      ? handleDataOrder(
          orders,
          adminCost,
          discount,
          deliveryCost,
          !isEventShop ? [...productIdList] : [...shopEventProductIdList]
        )
      : {}

  // const [timeRender, setTimeRender] = useState(0)
  const [sorting, setSorting] = useState({ field: 'eOrderNo', order: 'asc', type: 'number' })
  const [_totalOrderCount, setTotalOrderCount] = useState(totalOrderCount)
  const [searchString, setSearchString] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (groupId) {
      const userInfo = getUserInfo()
      const isUserInGroup = userInfo?.groups?.find((item) => item.gid === groupId)
      if (!isUserInGroup && !isByAdmin) {
        addToast('You are not in this group', { appearance: 'error', autoDismiss: true })
        history.push('/')
      }
    }
  }, [groupId, isByAdmin])

  const initialOrders = async (_eid) => {
    try {
      setIsLoading(true)
      await dispatch(getEventAndOrder(_eid, isEventShop, isByAdmin))
    } catch (error) {
      addToast(error.message || error?.msgResp, { appearance: 'error', autoDismiss: true })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    initialOrders(eventId)
  }, [eventId])

  useEffect(() => {
    setTotalOrderCount(totalOrderCount)
  }, [totalOrderCount])

  useEffect(() => {
    if (eventAndOrderById?.length > 0) {
      setIsLoading(false)
    }
  }, [JSON.stringify(eventAndOrderById)])

  const handelBroadcast = (mess) => {
    if (listOrder && listOrder.length > 0) {
      const listOrderExcludeCancelled = listOrder.filter(
        (item) => item.status !== HOST_ORDER_CANCELED && item.buyerStatus !== BUYER_ORDER_CANCELED
      )
      try {
        listOrderExcludeCancelled.forEach(async (e) => {
          await userApi.createNotifications({
            receiverId: e.uid,
            title: `Broadcast from [Event ${title}]`,
            message: mess,
          })
        })
        addToast('Message sent!', { appearance: 'success', autoDismiss: true })
      } catch (error) {
        addToast(error, { appearance: 'error', autoDismiss: true })
      }
    }
  }

  const isBuyerPaid = (_orders) => {
    if (_orders && _orders.length > 0) {
      return _orders.some(
        (e) => Number(e.buyerStatus) >= BUYER_PAID || Number(e.status) >= HOST_ORDER_VERIFIED
      )
    }
    return false
  }

  const onDeleteClicked = (_id, _orders, _groupId) => {
    if (!isBuyerPaid(_orders)) {
      const r = confirm('Are you sure?')
      if (r === true) {
        eventApi
          .deleteEvent(_id, isEventShop)
          .then(() => {
            dispatch(deleteEvent(_id, isEventShop))
            dispatch(deleteOrder(_id))
            dispatch(removeEventInGroup({ id: _id, groupId: _groupId }))
            if (getDeviceUser() === DESKTOP) {
              history.push('/event/list-hosted-by-group')
            } else {
              history.push('/event/list-my-events')
            }
          })
          .catch((error) => {
            const { msgResp } = error
            addToast(msgResp, { appearance: 'error', autoDismiss: true })
          })
      }
    } else {
      alert("Can't remove an event that has paid orders")
    }
  }

  const onChangeSearch = (event) => {
    setSearchString(event.target.value)
  }

  const listOrders = useMemo(() => {
    const codeRef = searchString.split('-')[1] // to exclude S- in search string, Ex: S-123456 -> 123456
    if (listOrder && listOrder.length > 0) {
      let listOrderSort = [...listOrder]
      if (searchString) {
        listOrderSort = listOrderSort.filter(
          (item) =>
            item.uPhone.toString().includes(searchString) ||
            item.uName.toLowerCase().includes(searchString.toLowerCase()) ||
            item.ref.slice(0, 6).includes(codeRef?.toLowerCase())
        )
      }
      if (sorting.field) {
        const reversed = sorting.order === 'asc' ? 1 : -1
        if (sorting.type === 'string') {
          listOrderSort = listOrderSort.sort(
            (a, b) => reversed * a[sorting.field]?.localeCompare(b[sorting.field])
          )
        } else {
          listOrderSort = listOrderSort.sort(
            (a, b) => reversed * (a[sorting.field] - b[sorting.field])
          )
        }
      }
      return listOrderSort
    }
  }, [sorting, searchString, listOrder])

  return (
    <React.Fragment>
      <div id='manage-order-page' className='container'>
        {isLoading ? <div>Loading...</div> : null}
        {!isLoading && errorGetEventAndOrder ? <div>No Event Found</div> : null}
        {!isLoading && !errorGetEventAndOrder ? (
          <>
            <div className='row-top'>
              <EventInfo
                title={title}
                closingTimestamp={convertTimestampToCurrentGMT(closingTimestamp)}
                pickupTimestamp={convertTimestampToCurrentGMT(pickupTimestamp)}
                closingTime={convertToCurrentGMT(closingTime)}
                pickupTime={convertToCurrentGMT(pickupTime)}
                id={id}
                shareCode={shareCode}
                numBuy={_totalOrderCount}
                eventAndOrderById={eventAndOrderById.find((item) => item.id === eventId)}
                isEventShop={isEventShop}
              />
              <NavBar
                eventId={eventId}
                eventAndOrderById={eventAndOrderById.find((item) => item.id === eventId)}
                handelBroadcast={handelBroadcast}
                status={status}
                // setTimeRender={setTimeRender}
                payable={payable}
                paynow={paynow}
                isBuyerPaid={isBuyerPaid(orders)}
                isEventShop={isEventShop}
              />

              {/* Value of paynow is "0" number */}
              {paynow !== undefined &&
              paynow !== null &&
              !isNaN(paynow) &&
              isNumber(paynow) &&
              paynow !== PAYNOW ? (
                <PayLater
                  // setTimeRender={setTimeRender}
                  discount={discount}
                  id={id}
                  deliveryCost={deliveryCost}
                  payable={payable}
                  status={status}
                  eventAndOrderById={eventAndOrderById.find((item) => item.id === eventId)}
                  closingTime={closingTime}
                  isBuyerPaid={isBuyerPaid(orders)}
                  isEventShop={isEventShop}
                />
              ) : null}
              <OrderSearch
                searchString={searchString}
                onChange={onChangeSearch}
                styles={{ marginTop: '1rem' }}
              />
              <div className='list-event-note'>
                Click on <strong>Names</strong> for Order Details and on{' '}
                <i className='icon-link'></i>
                for Payment Details.
              </div>
              {orders && orders.length ? (
                <OrderList
                  listOrder={listOrders}
                  // setTimeRender={setTimeRender}
                  setSorting={setSorting}
                  id={id}
                />
              ) : null}
            </div>
            <BoxPrice
              totalAllOrder={totalAllOrder}
              adminCostAllOrder={adminCostAllOrder}
              paymentCollected={paymentCollected}
              id={id}
            />
            <p
              className='link-share'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (isByAdmin) {
                  addToast('You are not allowed to delete order', {
                    appearance: 'error',
                    autoDismiss: true,
                  })
                  return
                }

                onDeleteClicked(id, orders, groupId)
              }}
            >
              DELETE
            </p>
          </>
        ) : null}
      </div>
    </React.Fragment>
  )
}

export default ManageOrder
