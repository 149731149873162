import { DEFAULT_PRODUCT } from '_utils/constant'
import { idGenerator } from '_utils/function'
import React from 'react'
import { useFieldArray } from 'react-hook-form'
import ProductItem from './ProductItem'

const ProductList = (props) => {
  const { control, register, setValue, errors, watch, getValues } = props
  const {
    fields,
    append: appendProduct,
    insert: insertProduct,
    remove: removeProduct,
  } = useFieldArray({
    control,
    name: 'products',
  })

  const watchFieldArray = watch('products')
  const controlledFields = fields.map((field, _index) => ({
    ...field,
    ...watchFieldArray[_index],
  }))

  const swapProduct = (oldIndex, newIndex) => {
    const a = controlledFields[oldIndex]
    const b = controlledFields[newIndex]
    const subItemA = a.subItems
    const subItemB = b.subItems

    setValue(`products.${oldIndex}`, b)
    setValue(`products.${newIndex}`, a)
    setValue(`products.${oldIndex}.subItems`, subItemB)
    setValue(`products.${newIndex}.subItems`, subItemA)
  }

  const onAddItems = () => {
    appendProduct({ ...DEFAULT_PRODUCT, id: idGenerator() })
  }

  return (
    <div className='detail-list-news'>
      {controlledFields.map((item, index) => (
        <ProductItem
          key={item.id}
          productIndex={index}
          totalListProduct={controlledFields?.length}
          {...{
            control,
            register,
            appendProduct,
            insertProduct,
            removeProduct,
            swapProduct,
            watch,
            setValue,
            errors,
            getValues,
          }}
          product={item}
        />
      ))}
      <div className='text-center mt-4'>
        <a className='c-txt-18 c-txt-gray' onClick={onAddItems}>
          Add Items
        </a>
      </div>
    </div>
  )
}

export default ProductList
