import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Form } from 'reactstrap'
import * as XLSX from 'xlsx'
import * as Yup from 'yup'

import eventApi from '_api/event'
import userApi from '_api/user'
import { SelectIdGroupContext } from '_context/SelectGroupContext'
import useQuery from '_hooks/useQuery'
import { getMyGroups } from '_redux/modules/group'
import {
  AVAILABLE_STOCK_DEFAULT,
  DEFAULT_PRODUCT,
  DEFAULT_QUANTITY,
  DOMAIN,
  GROUP_IS_HOST,
  LIMIT_PER_ORDER_DEFAULT,
  LIST_TIME,
  MINIMUM_ORDER_PER_PRODUCT,
  PACKAGE_TYPE_SCHEDULE_EVENT,
  PAYMENT_SERVICE_SCHEDULE_PRICE,
  TURN_ON,
} from '_utils/constant'
import {
  clearParamUrl,
  convertToCurrentGMT,
  decryptUserId,
  idGenerator,
  readFileToCreateEvent,
} from '_utils/function'
import {
  getCreateEventData,
  getUserInfo,
  removeCreateEventData,
  setCreateEventData,
  setUserInfo,
} from '_utils/localData'

import ModalHitPayPayment from '_components/ModalHitPayPayment'
import useLogout from '_hooks/useLogout'
import { getListDeliveryCreatedByHost } from '_redux/modules/delivery'
import { paymentScheduleEvent } from '_redux/modules/event'
import { getListPackage } from '_redux/modules/package'
import { getMyShops } from '_redux/modules/shop'
import DatePicker from '../components/DatePicker'
import EventDescription from '../components/EventDescription'
import EventPickupLocation from '../components/EventPickupLocation'
import EventTitle from '../components/EventTitle'
import PaymentOption from '../components/PaymentOption'

import { setSelectedEntity } from '../../../redux/modules/event'
import EventSelectEntity from '../components/EventSelectEntity'
import ModalCreateEvent from '../components/ModalCreateEvent'
import './style.scss'

const validationSchema = Yup.object().shape({
  // groupId: Yup.string('Please choose 1 your group!').required('Please choose 1 your group!'),
  entityId: Yup.string('Invalid entity').required('Please choose 1 your group or shop!'),
  title: Yup.string('Invalid title').required('Invalid title'),
  description: Yup.string('Invalid description').nullable().default(''),
  pickupAddress: Yup.string('Invalid pickup address').nullable().default(''),
  adminCost: Yup.number().default(0),
  deliveryOption: Yup.object().shape({
    deliveryZones: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        price: Yup.number(),
      })
    ),
    deliveryTime: Yup.string(),
    deliveryHourStart: Yup.string(),
    deliveryHourEnd: Yup.string(),
  }),
  addDelivery: Yup.bool(),
})

const EventCreate = () => {
  const history = useHistory()
  const location = useLocation()
  // const { pathname } = location
  const params = useQuery()
  const dispatch = useDispatch()
  const context = useContext(SelectIdGroupContext)
  const groupIdSelectedContext = context?.infoGroupSelected?.groupId

  const { addToast } = useToasts()
  const { myGroups } = useSelector((state) => state.group)
  const { listDeliveryCreatedByHost } = useSelector((state) => state.delivery)
  const { listPackage } = useSelector((state) => state.package)
  const { myShops, isLoadingMyShops, listShop } = useSelector((state) => state.shop)

  const userInfo = getUserInfo() || null
  const { id: userId } = userInfo
  const eid = params.get('id') || null
  const shopIdParam = params.get('shopId')

  let { listDeliveryCreated = null } =
    listDeliveryCreatedByHost.find((item) => item.hostId === userId) || {}

  listDeliveryCreated = listDeliveryCreated
    ? listDeliveryCreated.map((item) => ({
        ...item,
        deliveryTime: convertToCurrentGMT(item.deliveryTime),
      }))
    : null

  const fileInputRef = useRef()

  const [eventData, setEventData] = useState(getCreateEventData())
  const [hiddenExternalURL, setHiddenExternalURL] = useState(!!eventData?.extraFields?.[0])
  const [hiddenDelivery, setHiddenDelivery] = useState(eventData?.addDelivery)
  const [paynow, setPaynow] = useState(() => {
    if (eventData?.paynow === 0 || eventData?.paynow === true) return true
    return false
  })
  const [payTogether, setPayTogether] = useState(() => {
    if (eventData?.payTogether === 0 || eventData?.payTogether === false) return false
    return true
  })
  const [closingDate, setClosingDate] = useState(
    // getDataFromLocal(eventData?.closingTime) ||
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  )
  const [collectionDate, setCollectionDate] = useState(
    // getDataFromLocal(eventData?.pickupTime) ||
    new Date(new Date().getTime() + 24 * 7 * 60 * 60 * 1000)
  )
  const [collectionTimeStart, setCollectionTimeStart] = useState('10AM')
  const [collectionTimeEnd, setCollectionTimeEnd] = useState('5PM')
  const [externalUrl, setExternalUrl] = useState(eventData?.extraFields?.[0] || null)
  const [isLoading, setIsLoading] = useState(true)
  const [activeGroup, setActiveGroup] = useState([])
  const [activeShop, setActiveShop] = useState([])
  const [disableSelectGroup, setDisableSelectGroup] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const [isSubmittedPayment, setIsSubmittedPayment] = useState(false)
  // const [registerScheduleEvent, setRegisterScheduleEvent] = useState(false)
  const [messageCreateEvent, setMessageCreateEvent] = useState('')
  const [packageScheduleEvent, setPackageScheduleEvent] = useState(null)
  const [combinedListGroupShop, setCombinedListGroupShop] = useState([])
  const [isShowModalCreateEvent, setIsShowModalCreateEvent] = useState(false)

  const currentLoading = userInfo?.shopLinks?.length ? isLoadingMyShops : isLoading
  const userInfoGroups = userInfo?.groups || []
  // const userInfoShops = userInfo?.shopLinks || []

  const uid = params.get('uid')
  if (uid) {
    const decodedUid = decodeURIComponent(uid)
    const decryptedUid = decryptUserId(decodedUid, process.env.REACT_APP_SECRET_KEY_CRYPTO)

    if (decryptedUid !== userId) {
      useLogout()
    } else {
      clearParamUrl(['uid'], { history, location })
    }
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
    watch,
    control,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })
  const [hiddenNote, setHiddenNote] = useState(!!getValues()?.termConds?.length)

  const watchEntityId = watch('entityId')
  const watchSelectedShop = watch('selectedShop')

  // const watchIsUploadExcel = watch('isUploadExcel')

  const toggleExternalURL = () => setHiddenExternalURL(!hiddenExternalURL)
  const toggleShowModalCreateEvent = () => setIsShowModalCreateEvent(!isShowModalCreateEvent)
  const toggle = () => setIsShowModal(!isShowModal)

  const onSubmit = (data) => {
    delete data.collectionDate
    delete data.collectionTimeEnd
    delete data.collectionTimeStart

    if (data?.termConds?.length === 0) {
      data.termConds = []
    }
    const collectionStartIndex = LIST_TIME.findIndex((item) => item.text === collectionTimeStart)
    const collectionEndIndex = LIST_TIME.findIndex((item) => item.text === collectionTimeEnd)

    const { deliveryOption, addDelivery } = data

    const { deliveryTime = '', deliveryHourStart = '', deliveryHourEnd = '' } = deliveryOption

    let postData = {
      ...data,
      photoUrls: [],
      closingTime: `${moment(closingDate).format('DD-MM-YYYY')} 23:59`,
      pickupTime: `${moment(collectionDate).format('DD-MM-YYYY')} ${
        LIST_TIME[collectionStartIndex].value
      }:00`,
      pickupDuration:
        (LIST_TIME[collectionEndIndex]?.value - LIST_TIME[collectionStartIndex]?.value) * 3600,
      extraFields: externalUrl ? [externalUrl] : [],
      paynow,
      payable: paynow,
      payTogether,
    }

    const closingTimestamp = moment(postData.closingTime, 'DD-MM-YYYY HH:mm').unix()
    const pickupTimestamp = moment(postData.pickupTime, 'DD-MM-YYYY HH:mm').unix()
    const now = moment().unix()

    if (watchEntityId) {
      const findGroupIndex = myGroups?.findIndex((item) => item.id === watchEntityId)
      const findShopIndex = myShops.findIndex((item) => item.id === watchEntityId)

      if (findGroupIndex !== -1) {
        postData = { ...postData, groupId: watchEntityId, shopId: null, selectedShop: null }
      }

      if (findShopIndex !== -1) {
        postData = {
          ...postData,
          shopId: watchEntityId,
          groupId: null,
          selectedShop: watchSelectedShop,
        }
      }
    }

    if (addDelivery) {
      const deliveryStartIndex = LIST_TIME.findIndex((item) => item.text === deliveryHourStart)
      const deliveryEndIndex = LIST_TIME.findIndex((item) => item.text === deliveryHourEnd)
      const timeStart = LIST_TIME[deliveryStartIndex].value
      const timeEnd = LIST_TIME[deliveryEndIndex].value
      const timeStartString = timeStart >= 10 ? timeStart : `0${timeStart}`
      const deliveryDuration = (timeEnd - timeStart) * 3600
      if (deliveryDuration <= 0) {
        addToast('Invalid delivery time duration', { appearance: 'error', autoDismiss: true })
        return
      }
      postData = {
        ...postData,
        deliveryTime: `${deliveryTime} ${timeStartString}:00`,
        deliveryDuration,
      }

      const deliveryTimestamp = moment(postData.deliveryTime, 'DD-MM-YYYY HH:mm').unix()
      if (deliveryTimestamp <= closingTimestamp) {
        addToast('Delivery date must after closing date', {
          appearance: 'error',
          autoDismiss: true,
        })
        return
      }

      if (listDeliveryCreated && listDeliveryCreated.length) {
        const exitDeliveryTime = listDeliveryCreated.findIndex(
          (item) => item.deliveryTime === postData.deliveryTime
        )

        if (exitDeliveryTime !== -1) {
          addToast('Delivery exited', { appearance: 'error', autoDismiss: true })
          return
        }
      }
    }

    if (pickupTimestamp < now) {
      addToast('Invalid collection date', { appearance: 'error', autoDismiss: true })
      return
    }
    if (LIST_TIME[collectionStartIndex].value >= LIST_TIME[collectionEndIndex].value) {
      addToast('Invalid collection time', { appearance: 'error', autoDismiss: true })
      return
    }

    if (postData.id) {
      setCreateEventData(postData)
      history.push(`/event/update-items?id=${postData.id}`)
    } else {
      if (closingTimestamp < now) {
        addToast('Invalid closing date', { appearance: 'error', autoDismiss: true })
        return
      }

      if (pickupTimestamp < closingTimestamp) {
        addToast('Invalid collection date', { appearance: 'error', autoDismiss: true })
        return
      }
      let products = postData?.products
      if (products?.length === 0 || !products) {
        products = [{ ...DEFAULT_PRODUCT, id: idGenerator() }]
      }
      setCreateEventData({ ...postData, products })
      history.push('/event/create-items')
    }
  }

  const onCancel = () => {
    const currentEntityId = getValues('entityId')
    // removeCreateEventData()

    if (window.location.pathname.includes('/event/update')) {
      const getProducts = getValues('products')
      const getId = getValues('id')
      reset({
        entityId: currentEntityId,
        products: getProducts,
        id: getId,
      })
      setValue('pickupAddress', '')
      setValue('title', '')
      setValue('description', '')
    } else {
      // reset()
      setValue('pickupAddress', '')
      setValue('title', '')
      setValue('description', '')
    }
    setPaynow(false)
    setClosingDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
    setCollectionDate(new Date(new Date().getTime() + 24 * 7 * 60 * 60 * 1000))
    setExternalUrl(null)
  }

  const setValues = (objData) => {
    const keys = Object.keys(objData)
    keys.map((key) => setValue(key, objData[key]))
  }

  const onFileChanged = () => {
    const file = fileInputRef.current.files[0]
    const reader = new FileReader()

    reader.onload = (e) => {
      const data = e.target.result
      const workbook = XLSX.read(data, { type: 'binary' })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]

      if (!_.isEmpty(sheet)) {
        try {
          const newEvent = readFileToCreateEvent(sheet)
          const closingTimestamp = moment(newEvent.closingTime, 'DD-MM-YYYY HH:mm').unix()
          const pickupTimestamp = moment(newEvent.pickupTime, 'DD-MM-YYYY HH:mm').unix()
          let { closingTime } = newEvent
          let { pickupTime } = newEvent
          const now = moment().unix()
          const nowCheck = `${moment(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)).format(
            'DD-MM-YYYY'
          )} 23:59`

          if (pickupTimestamp < now) {
            pickupTime = `${nowCheck}`
          }
          if (closingTimestamp > pickupTimestamp || closingTimestamp < now) {
            closingTime = `${nowCheck}`
          }

          setValue('isUploadExcel', true)
          setCreateEventData({
            ...newEvent,
            closingTime,
            pickupTime,
            groupId: localStorage.getItem('GROUP_ID_SELECTED'),
            products: newEvent.products.map((item) => ({
              ...item,
              id: idGenerator(),
            })),
          })
          setEventData({
            ...newEvent,
            closingTime,
            pickupTime,
            groupId: localStorage.getItem('GROUP_ID_SELECTED'),
            products: newEvent.products.map((item) => ({
              ...item,
              id: idGenerator(),
            })),
          })
        } catch (error) {
          addToast('Please check the file', { appearance: 'error', autoDismiss: true })
        }
      }
    }

    reader.onerror = (e) => {
      addToast(`Failed to read file ${e}`, { appearance: 'error', autoDismiss: true })
    }

    reader.readAsBinaryString(file)
  }

  const handleEntityChange = (value) => {
    setValue('entityId', value)

    // Check if selected entity is shop
    const isShop = myShops.some((shop) => shop.id === value)
    const foundShop = myShops.find((shop) => shop.id === value)

    const entity = {
      type: isShop ? 'shop' : 'group',
      id: value,
    }
    dispatch(setSelectedEntity(entity))

    if (isShop) {
      // Fill form with shop data
      setValue('title', `Event of ${foundShop.shopName}`)
      setValue('description', foundShop.headline)
      setValue('pickupAddress', foundShop.shopAddress)
      setValue('selectedShop', foundShop)

      const sortedProducts = foundShop.products
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((product) => ({
          shopProductId: product.id,
          name: product.name,
          description: product.description,
          price: product.price,
          maxQuantity: AVAILABLE_STOCK_DEFAULT,
          defaultQuantity: DEFAULT_QUANTITY,
          limitPerOrder: LIMIT_PER_ORDER_DEFAULT,
          subItems: product.subItems,
          photoUrls: [product.photoUrls],
          minOrderQty: MINIMUM_ORDER_PER_PRODUCT,
        }))

      setValue('products', sortedProducts)
      localStorage.setItem('SELECTED_SHOP', JSON.stringify(foundShop))

      // Update URL with shopId
      if (!eid) {
        history.replace({ search: `?shopId=${value}` })
      }
    } else {
      if (shopIdParam && !eid) {
        clearParamUrl(['shopId'], { history, location })
      }
      // Clear form for group
      setValue('title', '')
      setValue('description', '')
      setValue('pickupAddress', '')
      setValue('selectedShop', null)
      setValue('products', [])
      localStorage.removeItem('SELECTED_SHOP')
    }

    setCreateEventData({
      ...eventData,
      shopId: isShop ? value : null,
      groupId: isShop ? null : value,
    })
  }

  const getListDeliveryByHost = async () => {
    const findShopIndex = myShops.findIndex((item) => item.id === watchEntityId)
    const isShop = findShopIndex !== -1
    try {
      await dispatch(getListDeliveryCreatedByHost(userId, isShop ? watchEntityId : null))
    } catch (error) {
      addToast(error?.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const confirmPayment = async (e) => {
    e.preventDefault()
    setIsSubmittedPayment(true)

    try {
      await dispatch(
        paymentScheduleEvent({
          packageId: packageScheduleEvent?.id,
          packageType: packageScheduleEvent?.packageType,
          startDate: moment().format('YYYY-MM-DD'),
        })
      )
      try {
        const { msgResp } = await userApi.getUser()
        setUserInfo(msgResp)
      } catch ({ msgResp }) {
        addToast(msgResp, { appearance: 'error', autoDismiss: true })
      }

      addToast('Payment successful', { appearance: 'success', autoDismiss: true })
      toggle()
    } catch (error) {
      addToast(error.msgResp, { appearance: 'error', autoDismiss: true })
    } finally {
      setIsSubmittedPayment(false)
    }
  }

  useEffect(() => {
    let isMounted = true // add this line

    const getGroups = async () => {
      try {
        await Promise.all([
          dispatch(getMyGroups),
          dispatch(getListPackage({ packageType: PACKAGE_TYPE_SCHEDULE_EVENT })),
        ])
      } catch (error) {
        addToast('Something went wrong', { appearance: 'error', autoDismiss: true })
      } finally {
        if (isMounted) setIsLoading(false) // check if component is still mounted
      }
    }
    setEventData(getCreateEventData())
    getGroups()

    const getShopByUser = async () => {
      try {
        await dispatch(getMyShops())
      } catch (error) {
        addToast(error?.message, { appearance: 'error', autoDismiss: true })
      }
    }
    getShopByUser()

    const getEventDetail = async (id) => {
      const { msgResp } = await eventApi.getEventDetail({
        eid: id,
        isGetDeliveryZones: true,
      })
      for (let i = 0; i < msgResp.products.length; i++) {
        msgResp.products[i].pid = msgResp.products[i].id
      }
      if (isMounted) {
        // check if component is still mounted

        setEventData({ ...msgResp, entityId: msgResp?.groupId || msgResp?.shopId })
        setCreateEventData({ ...msgResp, entityId: msgResp?.groupId || msgResp?.shopId })
      }
    }

    const id = params.get('id')

    if (id && (!eventData || Object.entries(eventData).length === 0)) {
      getEventDetail(id)
    } else if (myGroups && myGroups.length) {
      getListDeliveryByHost()
    }

    const groupId = params.get('groupId')
    if (groupId && isMounted && myGroups && myGroups.length) {
      // check if component is still mounted
      const listGroupIsHost = myGroups.filter((item) => item.isHost === GROUP_IS_HOST)
      setActiveGroup(listGroupIsHost)
      setEventData({ ...eventData, groupId, entityId: groupId })
    }

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    setDisableSelectGroup(false)
    const id = params.get('id')
    const groupId = params.get('groupId')

    if (!id && !groupId && myGroups && myGroups.length) {
      setValue('groupId', myGroups.filter((item) => item.isHost === GROUP_IS_HOST)?.[0]?.gid)

      const { domainFeatures = [] } = userInfo
      const currDomainFeature = domainFeatures.find((d) => d.domainName === DOMAIN) || {}

      // eslint-disable-next-line no-shadow
      const activeGroup = myGroups.filter((item) => item.id === currDomainFeature.groupId)

      if (activeGroup && Object.keys(activeGroup).length) {
        setActiveGroup(activeGroup)

        dispatch(setSelectedEntity({ type: 'group', id: activeGroup[0].id }))
        setValue('entityId', activeGroup[0].id)
        setDisableSelectGroup(true)
      } else {
        const listGroupIsHost = myGroups.filter((item) => item.isHost === GROUP_IS_HOST)
        setActiveGroup(listGroupIsHost)
        dispatch(
          setSelectedEntity({
            type: 'group',
            id: listGroupIsHost?.[0]?.id,
          })
        )
        setValue('entityId', listGroupIsHost?.[0]?.id)
      }
    }

    if (myShops.length && listShop.length) {
      setActiveShop(
        myShops.filter((item) => item.active === TURN_ON && listShop.some((s) => s.id === item.id))
      )

      if (shopIdParam) {
        handleEntityChange(shopIdParam)
      }
    }
  }, [JSON.stringify(myGroups), JSON.stringify(myShops), JSON.stringify(listShop)])

  useEffect(() => {
    if (!isLoading && !isLoadingMyShops) {
      setCombinedListGroupShop(
        [...activeGroup, ...activeShop]
          .sort((a, b) => {
            if (a.id && !b.id) return -1
            if (!a.id && b.id) return 1
            return 0
          })
          .map((item) => ({
            ...item,
            name: item.name || item.shopName,
          }))
      )
    }

    if (!isLoading) {
      if (
        userInfoGroups.length === 0 ||
        (userInfoGroups.length > 0 &&
          (userInfoGroups.every((g) => !g.isHost) || userInfoGroups.every((g) => !g.isAdmin)))
      ) {
        removeCreateEventData()
        setMessageCreateEvent(
          userInfoGroups.length === 0
            ? 'You need to join a group, be an admin/host of the group before creating an event.'
            : 'You need to be an admin/host of the group before creating an event.'
        )
        setIsShowModalCreateEvent(true)
      }
    }
  }, [
    isLoading,
    isLoadingMyShops,
    JSON.stringify(activeGroup),
    JSON.stringify(activeShop),
    JSON.stringify(userInfoGroups),
  ])

  useEffect(() => {
    if (groupIdSelectedContext) {
      setValue('groupId', groupIdSelectedContext)
      handleEntityChange(groupIdSelectedContext)
    }
  }, [groupIdSelectedContext])

  useEffect(() => {
    if (eventData) {
      if (eventData?.id) setDisableSelectGroup(true)

      if (eventData?.groupId || eventData?.shopId) {
        dispatch(
          setSelectedEntity({
            type: eventData?.groupId ? 'group' : 'shop',
            id: eventData?.groupId || eventData?.shopId,
          })
        )
        setValue('entityId', eventData?.groupId || eventData?.shopId)
      }
      setValues(eventData)

      if (eventData?.shopId && eventData?.products && eventData.products.length) {
        const mappedProducts = eventData?.products?.map((item) => ({
          ...item,
          shopProductId: item.id || item.pid,
        }))
        setValue('products', mappedProducts)
      }

      if (eventData?.closingTime && eventData?.pickupTime) {
        const closingTime = eventData.closingTime.split(' ')[0].split('-').reverse()
        const pickupTime = eventData.pickupTime.split(' ')[0].split('-').reverse()
        setCollectionDate(new Date(pickupTime[0], pickupTime[1] - 1, pickupTime[2]))
        setClosingDate(new Date(closingTime[0], closingTime[1] - 1, closingTime[2]))
      }
      if (eventData?.pickupTime && eventData?.pickupDuration) {
        const { pickupTime } = eventData
        const pickupHour = Number(pickupTime.split(' ')[1].split(':')[0])
        const pickupDuration = eventData?.pickupDuration || 0

        const getIndex = (hour) => LIST_TIME.findIndex((item) => item.id === hour)

        const indexStart = getIndex(pickupHour)
        const indexEnd = getIndex((pickupHour + pickupDuration / 3600) % 24)

        setCollectionTimeStart(LIST_TIME[indexStart]?.text)
        setCollectionTimeEnd(LIST_TIME[indexEnd]?.text)
      }
    }
  }, [eventData])

  useEffect(() => {
    setHiddenNote(!!getValues()?.termConds?.length)
    setHiddenDelivery(!!getValues()?.addDelivery)
  }, [getValues()?.termConds, getValues()?.addDelivery])

  useEffect(() => {
    setValue('collectionDate', collectionDate)
    setValue('collectionTimeStart', collectionTimeStart)
    setValue('collectionTimeEnd', collectionTimeEnd)
  }, [collectionDate, collectionTimeStart, collectionTimeEnd])

  useEffect(() => {
    if (listPackage?.length) {
      const currentPack = listPackage.find(
        (item) => item.packageType === PACKAGE_TYPE_SCHEDULE_EVENT
      )
      setPackageScheduleEvent(currentPack)
    }
  }, [JSON.stringify(listPackage)])

  // useEffect(() => {
  //   if (userInfo?.features?.scheduleEvent) {
  //     setRegisterScheduleEvent(false)
  //   } else {
  //     setRegisterScheduleEvent(true)
  //   }
  // }, [userInfo])

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className='pb-3'>
        <div className='row-top create-event-page'>
          {currentLoading && !combinedListGroupShop.length ? (
            <div>Loading...</div>
          ) : (
            <EventSelectEntity
              options={combinedListGroupShop}
              register={register('entityId', {
                onChange: (e) => handleEntityChange(e.target.value),
              })}
              disabled={!!params.get('id') || disableSelectGroup}
              selected={eventData?.entityId}
              error={errors?.entityId?.message}
              setValue={setValue}
              entityId={watchEntityId}
              isHasShop={!!activeShop.length}
            />
          )}
          <div className='c-form c-form--line'>
            <div className='form-group'>
              {!watchSelectedShop ? (
                <div className='upload-excel-group'>
                  <label className='upload-excel'>
                    Upload excel (<a href='/misc/sample.xlsx'>sample</a>)
                    <input
                      type='file'
                      accept='.xlsx'
                      onChange={onFileChanged}
                      ref={fileInputRef}
                      className='d-none'
                    />
                  </label>
                  {/* {registerScheduleEvent ? (
                <label
                  className='register-schedule-event'
                  data-toggle='tooltip'
                  data-placement='right'
                  title='This feature allows the host to set a time
                   to be able to publish the event.
                   Before the public, the event will not
                   be seen by the buyer.'
                  onClick={toggle}
                >
                  The schedule-event feature is available, register to use it?
                </label>
              ) : null} */}
                </div>
              ) : null}
            </div>
            <EventTitle register={register('title')} error={errors?.title?.message} />
            <EventDescription
              register={register('description')}
              error={errors?.description?.message}
            />
            <EventPickupLocation
              register={register('pickupAddress')}
              error={errors?.pickupAddress?.message}
            />
            <div className='row mt-3'>
              <div className='col-6'>
                <div className='form-group'>
                  <label>
                    Closing Date<span className='sys'>*</span>
                  </label>
                  <div className='have-icon'>
                    <DatePicker onChange={setClosingDate} value={closingDate} />
                  </div>
                </div>
              </div>
              <div className='col-6 col-6-date'>
                <div className='form-group'>
                  <label>
                    Collection Date<span className='sys'>*</span>
                  </label>
                  <div className='have-icon'>
                    <DatePicker
                      onChange={(date) => {
                        setCollectionDate(date)
                        setValue('deliveryOption.deliveryTime', moment(date).format('DD-MM-YYYY'))
                      }}
                      value={collectionDate}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <label>Collection Time</label>
                  <div className='form-group-time'>
                    <div className='form-group-time__item'>
                      <select
                        className='form-control'
                        value={collectionTimeStart}
                        onChange={(e) => {
                          setCollectionTimeStart(e.target.value)
                          setValue('deliveryOption.deliveryHourStart', e.target.value)
                        }}
                      >
                        {LIST_TIME.map((item, index) => (
                          <option key={index}>{item.text}</option>
                        ))}
                      </select>
                    </div>
                    <span>-</span>
                    <div className='form-group-time__item'>
                      <select
                        className='form-control'
                        value={collectionTimeEnd}
                        onChange={(e) => {
                          setCollectionTimeEnd(e.target.value)
                          setValue('deliveryOption.deliveryHourEnd', e.target.value)
                        }}
                      >
                        {LIST_TIME.map((item, index) => (
                          <option key={index}>{item.text}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PaymentOption
            register={register}
            toggleExternalURL={toggleExternalURL}
            hiddenNote={hiddenNote}
            hiddenExternalURL={hiddenExternalURL}
            externalUrl={externalUrl}
            setExternalUrl={setExternalUrl}
            setValue={setValue}
            paynow={paynow}
            setPaynow={setPaynow}
            payTogether={payTogether}
            setPayTogether={setPayTogether}
            watch={watch}
            control={control}
            getValues={getValues}
            hiddenDelivery={hiddenDelivery}
            setHiddenDelivery={setHiddenDelivery}
            eid={eid}
          />
        </div>
        <div className='row-bot mt-4'>
          <div className='row'>
            <div className='col-6'>
              <button type='button' className='btn btn--solid btn--solid03' onClick={onCancel}>
                CANCEL
              </button>
            </div>
            <div className='col-6'>
              <button className='btn btn--main' type='submit'>
                NEXT
              </button>
            </div>
          </div>
        </div>
        <ModalHitPayPayment
          isShow={isShowModal}
          toggle={toggle}
          isSubmittedPayment={isSubmittedPayment}
          service={{ type: 'Schedule event', price: PAYMENT_SERVICE_SCHEDULE_PRICE }}
          confirmPayment={confirmPayment}
        />
      </Form>
      <ModalCreateEvent
        isShow={isShowModalCreateEvent}
        toggle={toggleShowModalCreateEvent}
        message={messageCreateEvent}
      />
    </>
  )
}

export default EventCreate
