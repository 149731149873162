/* eslint-disable function-paren-newline */
import { yupResolver } from '@hookform/resolvers/yup'
import uploadApi from '_api/upload'
import cancelImage from '_images/cancel.png'
import noImage from '_images/noimage02.svg'
import { DEFAULT_PRODUCT } from '_utils/constant'
import { idGenerator } from '_utils/function'
import { getCreateEventData, setCreateEventData } from '_utils/localData'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from 'reactstrap'
import * as Yup from 'yup'
import ProductList from '../components/ProductList'
import './style.scss'

const validationSchema = () =>
  Yup.object().shape({
    products: Yup.array().of(
      Yup.object().shape({
        pid: Yup.string(),
        name: Yup.string().required('Product name is required'),
        description: Yup.string().nullable().notRequired(),
        price: Yup.number()
          .typeError('Product price is required')
          .required('Product price is required'),
        maxQuantity: Yup.number()
          .typeError('Available stock is required')
          .required('Available stock is required')
          .test(
            'maxQuantity',
            'Max quantity exceeds available stock',
            function validateMaxQuantity(value) {
              const { path, createError } = this

              if (value <= 0) {
                return createError({
                  path,
                  message: 'Max quantity cannot be less than or equal 0',
                })
              }
              return true
            }
          ),
        defaultQuantity: Yup.number()
          .typeError('Default quantity is required')
          .default(0)
          .required('Default quantity is required'),
        limitPerOrder: Yup.number()
          .typeError('Limit per order is required')
          .required('Limit per order is required'),
        minOrderQty: Yup.number()
          .typeError('Minimum order quantity must be greater than 0')
          .min(1, 'Minimum order quantity must be greater than 0')
          .required('Minimum order quantity must not be blank.')
          .test(
            'minOrderQty',
            'Minimum order quantity cannot be greater than max quantity',
            function validateMinOrderQty(value) {
              const { path, createError } = this
              if (value > this.parent.maxQuantity) {
                return createError({
                  path,
                  message: 'Minimum order quantity cannot be greater than max quantity',
                })
              }
              return true
            }
          ),
        subItems: Yup.array().of(
          Yup.object().shape({
            type: Yup.string().required('Sub item name is required'),
            required: Yup.boolean().default(false),
            list: Yup.array().of(
              Yup.object().shape({
                name: Yup.string().required('Option name is required'),
                price: Yup.number()
                  .typeError('Option price is required')
                  .required('Option price is required'),
              })
            ),
          })
        ),
      })
    ),
  })

const EventCreateItem = () => {
  const history = useHistory()
  const { addToast } = useToasts()
  const createEventData = getCreateEventData()
  const selectedShopLocal = localStorage.getItem('SELECTED_SHOP')
  const parsedSelectedShop = selectedShopLocal ? JSON.parse(selectedShopLocal) : null

  if (!createEventData) {
    history.push('/event/create')
  }

  const [listImageEvent, setListImageEvent] = useState(
    createEventData?.photoUrls.map((item) => ({ url: item, file: '' } || [])) ?? []
  )

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = useForm({
    defaultValues: createEventData || { products: [{ ...DEFAULT_PRODUCT, id: idGenerator() }] },
    resolver: yupResolver(validationSchema(parsedSelectedShop)),
    mode: 'all',
  })

  const onUserImageUploaded = (event) => {
    const { files } = event.target
    if (files.length + listImageEvent.length > 5) {
      addToast('Maximum images for event is 5!', { appearance: 'warning', autoDismiss: true })
      return
    }

    for (let i = 0; i < files.length; i++) {
      if (i < 5) {
        listImageEvent.push({ url: URL.createObjectURL(files[i]), file: files[i] })
      }
    }
    setListImageEvent([...listImageEvent])
  }

  const handleOnRemoveImg = (index) => {
    listImageEvent.splice(index, 1)
    setListImageEvent([...listImageEvent])
  }

  const onSubmit = async (_data) => {
    const list = listImageEvent
    for (let i = 0; i < list.length; i++) {
      const element = list[i]
      if (element.file) {
        const { msgResp } = await uploadApi.uploadPhoto([element.file])
        element.url = msgResp.url
      }
    }

    if (list.length === 0) {
      _data.photoUrls = []
    } else {
      _data.photoUrls = list.map((item) => item.url)
    }

    if (_data?.products?.length === 0) {
      addToast('You must have at least 1 product!!!', { appearance: 'error', autoDismiss: true })
      return
    }
    _data.products = _data.products?.map((product) => {
      const isHasPhotoUrls =
        product.photoUrls && product.photoUrls.length > 0 && product.photoUrls.every((item) => item)
      return {
        ...product,
        minOrderQty: product?.minOrderQty ? product?.minOrderQty : 1,
        subItems: product.subItems.map(({ id, ...subItem }) => subItem),
        photoUrls: isHasPhotoUrls ? product.photoUrls : [],
      }
    })

    setCreateEventData({
      ..._data,
    })
    if (_data.id) {
      history.push(`/event/update-confirm?id=${_data.id}`)
    } else {
      history.push('/event/created-confirm')
    }
  }

  // use for display error message with "errorTypes" in this function
  const getProductsWithErrors = (_errors, products) => {
    const errorRefs = _errors.products
      .filter((item) => item !== null)
      .flatMap((error, index) => {
        const errorTypes = ['maxQuantity', 'minOrderQty', 'limitPerOrder']
        return errorTypes
          .filter((type) => error[type])
          .map((type) => ({
            index,
            ref: error[type].ref.name,
            message: error[type].message,
            type,
            id: error.product.id || error.product.shopProductId,
          }))
      })

    const filteredProducts = products
      .map((product) => {
        const error = errorRefs.find((errorRef) => {
          const errorRefIndex = errorRef.ref.split('.')[1]
          const errorRefId = errorRef.id

          const isMatch =
            product.shopProductId === errorRefId || product.id === errorRefId
              ? errorRef.ref === `products.${errorRefIndex}.${errorRef.type}`
              : false

          return isMatch
        })

        return {
          ...product,
          error,
        }
      })
      .filter((product) => product.error !== undefined)

    return filteredProducts
  }

  const onError = (_errors) => {
    const products = watch('products')
    _errors.products = _errors.products.map((error, index) => ({
      ...error,
      product: products[index],
    }))

    const productsWithErrors = getProductsWithErrors(_errors, products)
    productsWithErrors.forEach((product) => {
      const errorMessage = `Product "${product.name}" has an error: ${product.error.message}`
      addToast(errorMessage, { appearance: 'error', autoDismiss: true })
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className='create-items-page'>
      <div className='row-top'>
        <h4 className='text-center c-ttl-blue mt-4'>Banner</h4>
        <div className='d-flex justify-content-end w-100'>
          <span className='add-more-image'>Remain: ({5 - listImageEvent.length} photos left)</span>
        </div>

        <div className='c-form upload-image-square mt-3 mb-3'>
          <div className='upload-image'>
            <img src={noImage} alt='' />
            <div className='upload-image-txt'>
              <span>Upload Image</span>
            </div>
            <div className='u-file-upload__item'>
              <div className='u-file-upload__wrap'>
                <a className='c-upload preview-images-zone'>
                  <input
                    type='file'
                    accept='image/*'
                    className='inputFile'
                    onChange={onUserImageUploaded}
                    readOnly
                    multiple
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <h4 className='text-center c-ttl-blue mt-4'>Items for Sales</h4>

        <div className='list-image'>
          {listImageEvent.map((item, index) => (
            <div key={index} className='upload-image'>
              <img src={item.url} alt='' className='main-image' />
              <img
                src={cancelImage}
                className='cancel-btn'
                onClick={() => handleOnRemoveImg(index)}
              />
            </div>
          ))}
        </div>

        <ProductList {...{ control, register, getValues, setValue, errors, watch }} />
      </div>

      <div className='row-bot mt-4'>
        <div className='row'>
          <div className='col-6'>
            <button
              className='btn btn--solid btn--solid03'
              onClick={() => {
                const currentValues = getValues()
                setCreateEventData({
                  ...currentValues,
                  entityId: createEventData?.entityId || currentValues.entityId,
                })

                let url = ''
                if (createEventData?.id) {
                  url = `/event/update?id=${createEventData.id}`
                } else {
                  url = '/event/create'
                }

                // Preserve shopId if exists
                const shopId = createEventData?.shopId
                if (shopId) {
                  url += url.includes('?') ? `&shopId=${shopId}` : `?shopId=${shopId}`
                }

                history.push(url)
              }}
            >
              PREVIOUS
            </button>
          </div>
          <div className='col-6'>
            <button className='btn btn--main' type='submit' disabled={isSubmitting}>
              {isSubmitting ? <Spinner color='light' size='sm' /> : 'NEXT'}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default EventCreateItem
